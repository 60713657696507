import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AxiosApiClient } from "@smallstack/axios-api-client";
import {
  ErrorMessageHandler,
  TokenService,
} from "@smallstack/common-components";
import {
  LocaleService,
  MaterialDialogNotifier,
  MaterialSnackbarNotifier,
  NotificationService,
  SmallstackI18nModule,
  TranslationLoader,
  TranslationStore,
} from "@smallstack/i18n-components";
import {
  loadCommon,
  loadCommonActions,
  loadCommonErrors,
} from "@smallstack/language-packs";
import config from "../../config.json";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizedContainerComponent } from "./components/authorized-container/authorized-container.component";
import { BackButtonComponent } from "./components/back-button.component";
import { ChooseFileDialogComponent } from "./components/choose-file-dialog/choose-file-dialog.component";
import { CurrentTaxReturnContainerComponent } from "./components/current-tax-return-container/current-tax-return-container.component";
import { SettingsButtonComponent } from "./components/settings-button/settings-button.component";
import { UploadedFileListComponent } from "./components/uploaded-file-list/uploaded-file-list.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserAuthenticatedGuard } from "./guards/user-authenticated.guard";
import { AblageComponent } from "./pages/ablage/ablage.component";
import { ArbeitnehmerComponent } from "./pages/arbeitnehmer/arbeitnehmer.component";
import { BackofficeUserPageComponent } from "./pages/backoffice-user-page/backoffice-user-page.component";
import { EinreichenComponent } from "./pages/einreichen/einreichen.component";
import { FeesCalculatorFormComponent } from "./pages/fees-calculator/fees-calculator-form.component";
import { HomeComponent } from "./pages/home/home.component";
import { ImmobilienComponent } from "./pages/immobilien/immobilien.component";
import { KinderComponent } from "./pages/kinder/kinder.component";
import { RentnerComponent } from "./pages/rentner/rentner.component";
import { ScanComponent } from "./pages/scan/scan.component";
import { SonderausgabenComponent } from "./pages/sonderausgaben/sonderausgaben.component";
import { SparenComponent } from "./pages/sparen/sparen.component";
import { StammdatenComponent } from "./pages/stammdaten/stammdaten.component";
import { LoginComponent } from "./pages/user/login/login.component";
import { PasswordResetComponent } from "./pages/user/password-reset/password-reset.component";
import { RegisterComponent } from "./pages/user/register/register.component";
import { DocCountPipe } from "./pipes/doccount.pipe";
import { FileSizePipe } from "./pipes/filesize.pipe";
import { FileTypePipe } from "./pipes/filetype.pipe";
import { TaxReturnService } from "./services/tax-return.service";

@NgModule({
  declarations: [
    AppComponent,
    BackofficeUserPageComponent,
    DashboardComponent,
    SonderausgabenComponent,
    KinderComponent,
    ImmobilienComponent,
    RentnerComponent,
    SparenComponent,
    ArbeitnehmerComponent,
    StammdatenComponent,
    AblageComponent,
    ScanComponent,
    FeesCalculatorFormComponent,
    EinreichenComponent,
    BackButtonComponent,
    SettingsButtonComponent,
    ChooseFileDialogComponent,
    LoginComponent,
    RegisterComponent,
    AuthorizedContainerComponent,
    CurrentTaxReturnContainerComponent,
    FileSizePipe,
    FileTypePipe,
    DocCountPipe,
    UploadedFileListComponent,
    HomeComponent,
    PasswordResetComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatListModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    SmallstackI18nModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MatDatepickerModule,
    TokenService,
    TaxReturnService,
    TranslationStore,
    LocaleService,
    ErrorMessageHandler,
    MaterialSnackbarNotifier,
    MaterialDialogNotifier,
    UserAuthenticatedGuard,
    NotificationService,
    {
      provide: AxiosApiClient,
      useFactory: (tokenService: TokenService) => {
        return new AxiosApiClient({
          apiUrl: config.cloud.url,
          resellerId: config.cloud.resellerId,
          tenantId: config.cloud.tenantId,
          token: () => tokenService.getLoginToken(),
          authTenantId: () => config.cloud.tenantId,
        });
      },
      deps: [TokenService],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LocaleService, TranslationLoader, TranslationStore],
      useFactory:
        (
          localeService: LocaleService,
          translationLoader: TranslationLoader,
          translationStore: TranslationStore
        ) =>
        async () => {
          translationLoader.register([
            loadCommon,
            loadCommonErrors,
            loadCommonActions,
          ]);
          translationStore.addJsonTranslations(["de_de"], {
            common: {
              ok: ["ok"],
              dismiss: ["schließen"],
            },
            notifications: {
              success: {
                title: ["Das hat geklappt"],
              },
              error: {
                title: ["Fehler"],
              },
            },
            errors: {
              user: {
                email_already_exists: [
                  "Zu dieser E-Mail existiert bereits ein Konto in unserem System. Bitte verwende eine andere E-Mail Adresse!",
                ],
                invalid_password_uppercasemissing: [
                  "Ihr Passwort sollte min. 1 Großbuchstaben enthalten!",
                ],
                invalid_password_lowercasemissing: [
                  "Ihr Passwort sollte min. 1 Kleinbuchstaben enthalten!",
                ],
                invalid_password_notatleast8characterslong: [
                  "Ihr Passwort ist zu kurz. Es sollte min. 8 Zeichen lang sein!",
                ],
                invalid_password_nospecialcharacter: [
                  "Ihr Passwort sollte min. 1 Sonderzeichen enthalten! Zulässige Sonderzeichen sind !#$%&'()*+,-./\\:;<=>?@[]^_`{|}~ ",
                ],
              },
              validations: {
                length: [
                  "Ihr Passwort ist zu kurz. Es sollte min. 8 Zeichen lang sein!",
                ],
              },
              http: {
                400: ["Ungültige Eingabe"],
                404: ["Nicht gefunden"],
              },
            },
          });

          await localeService.getAllLocales();
          await localeService.detectLocale();
        },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    translationStore: TranslationStore,
    taxReturnService: TaxReturnService,
    translationLoader: TranslationLoader,
    localeService: LocaleService
  ) {}
}
