import { Component, ContentChild, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { AxiosApiClient, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { NotificationService } from "@smallstack/i18n-components";
import { Store } from "@smallstack/store";
import { currentTaxReturn$$, currentUser$$ } from "src/app/app.state";
import { TaxReturn } from "src/app/models/tax-return.entity";
import { TaxReturnService } from "src/app/services/tax-return.service";

@Component({
  selector: "current-tax-return-container",
  templateUrl: "./current-tax-return-container.component.html"
})
export class CurrentTaxReturnContainerComponent implements OnDestroy, OnInit {

  @ContentChild(TemplateRef)
  public templateRef: TemplateRef<any>;

  public currentTaxReturn: Store<TaxReturn> = currentTaxReturn$$;
  public currentUser: UserDto;

  constructor(private axiosApiClient: AxiosApiClient, private taxReturnService: TaxReturnService, private notificationService: NotificationService) { }

  public async ngOnInit() {
    currentUser$$.setLoading().setValue((await this.axiosApiClient.get(UsersApi).getMyUser()).data);
    this.currentUser = currentUser$$.value;
  }

  public ngOnDestroy(): void {
    // hier könnte man abfragen, ob geänderte Daten gespeichert wurden?
  }

  public saveTaxReturn(currentTaxReturn: TaxReturn) {
    // if (this.currentUser?.lastName !== currentTaxReturn.profile?.lastName) {
    //   this.currentUser.lastName = currentTaxReturn.profile?.lastName;
    //   if (this.currentUser?.firstName !== currentTaxReturn.profile?.firstName)
    //     this.currentUser.firstName = currentTaxReturn.profile?.firstName;
    //   this.axiosApiClient.patchUser(this.currentUser.id, this.currentUser);
    // }

    this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(currentTaxReturn), {
      successMsg: "Daten gespeichert",
      errorMsg: "Speichern fehlgeschlagen"
    });
  }

}
