<mat-toolbar class="top-toolbar purple">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/einreichen_icon_light.svg" />
            <span class="mat-title" style="margin: 0px;">Einreichen {{currentTaxYear}}</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row">
            <div class="top right speech-bubble" fxFlex="65">
                <p class="mat-subtitle bea-talks">Hey super, du hast es bis hierher geschafft. Jetzt bist du nur noch
                    ein paar Klicks davon entfernt, deine Steuererklärung einzureichen. Aber bedenke, mit dieser Aktion
                    signalisierst du mir, dass du fertig bist und beauftragst mich offiziell, deine Erklärung zu
                    bearbeiten und an das Finanzamt zu schicken.</p>
            </div>
            <div class="bea thumbsup" fxFlex="34">
            </div>
        </div>
    </div>
    <div class="status" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <!-- <div>
            <h3>Status</h3>
            <p class="mat-body">Hier siehst du deine aktuelle Steuererklärung, die du gerade in Bearbeitung hast. Der
                Fortschritt zeigt dir, ob du an alles gedacht hast.</p>
        </div> -->
        <div class="card-box transparent" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <div class="content">
                <mat-card-title>
                    Steuererklärung {{currentTaxYear}} <button mat-icon-button color="accent"
                        (click)="editTaxYear(currentTaxYear)" matTooltip="Bearbeiten"
                        [disabled]="!getStatus(TaxReturnStatus.processing)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-card-title>

                <p class="mat-caption">
                    <mat-card-subtitle *ngIf="currentTaxReturn">
                        Status: {{currentTaxReturn?.status}}
                    </mat-card-subtitle>
                </p>
                <img *ngIf="getStatus(TaxReturnStatus.processing)" class="status-icon"
                    src="assets/icons/daten_erfassen.svg" />
                <img *ngIf="getStatus(TaxReturnStatus.submitted)" class="status-icon"
                    src="assets/icons/einreichen_icon.svg" />
                <img *ngIf="getStatus(TaxReturnStatus.completed)" class="status-icon"
                    src="assets/icons/hacken_icon.svg" />
                <!-- <p class="mat-caption">
                    <mat-card-subtitle>
                        Fortschritt: 69%
                    </mat-card-subtitle>
                    <br>7 Werte erfasst / 13 Dokumente hochgeladen
                </p> -->
            </div>
            <div *ngIf="currentTaxReturn" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px" style="width: 100%;">
                <div class="submit-checkboxes" fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="currentTaxReturn.emailCommunicationAccepted" color="primary">
                        <span class="mat-caption">Kommunikationserleichterung akzeptieren</span>
                    </mat-checkbox>
                    <mat-icon color="primary" (click)="showHint('emailCommunication')">help</mat-icon>
                </div>
                <div class="submit-checkboxes" fxLayout="row" fxLayoutAlign="start center">
                    <mat-checkbox [(ngModel)]="currentTaxReturn.invoiceViaEmail" color="primary" required>
                        <span class="mat-caption">Rechnungen als PDF-Anhang per E-Mail</span>
                    </mat-checkbox>
                    <mat-icon color="primary" (click)="showHint('invoiceViaMail')">help</mat-icon>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" style="width: 100%;">
                <button mat-flat-button class="status-button submit" type="button" [disabled]="isSubmitting" (click)="submitCurrentTaxReturn()" color="primary"
                    [disabled]="!getStatus(TaxReturnStatus.processing) || currentTaxReturn.invoiceViaEmail !== true">
                    <img src="assets/icons/einreichen_icon_light.svg"
                        style="width: 20px;height: 20px;margin-right: 8px;" />
                        <span *ngIf="!isSubmitting">STEUERERKLÄRUNG EINREICHEN!</span>
                        <span *ngIf="isSubmitting">WIRD EINGEREICHT...</span>
                </button>
            </div>
        </div>
        <p class="mat-caption alert" *ngIf="getStatus(TaxReturnStatus.submitted)">
            Diese Erklärung wurde bereits eingereicht
        </p>
        <p class="mat-caption alert" *ngIf="getStatus(TaxReturnStatus.completed)">
            Diese Erklärung ist bereits abgeschlossen und an das Finanzamt übertragen.
        </p>
        <div fxLayout="column" fxLayoutAlign="start center" *ngIf="!getStatus(TaxReturnStatus.processing)">
            <h3 class="mat-subtitle">Neue Steuererklärung starten</h3>
            <button mat-stroked-button class="create-tax-button" color="primary" #ddTrigger="matMenuTrigger"
                [matMenuTriggerFor]="dd">Jahr
                auswählen&nbsp;
                <mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #dd="matMenu" [overlapTrigger]="false">
                <div class="dropdown">
                    <ng-container *ngFor="let year of years">
                        <button *ngIf="year !== currentTaxYear" mat-menu-item (click)="editTaxYear(year)"
                            [value]="year">{{year}}</button>
                    </ng-container>
                </div>
            </mat-menu>
        </div>
    </div>
</div>