import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { TokenService } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { currentTaxReturn$$, currentUser$$ } from "src/app/app.state";
import { TaxReturnService } from "src/app/services/tax-return.service";


@Component({
  templateUrl: "./login.component.html"
})
export class LoginComponent {

  public formGroup: FormGroup;

  constructor(formBuilder: FormBuilder, private tokenService: TokenService, private axiosApiClient: AxiosApiClient, private router: Router, private notificationService: NotificationService, private taxReturnService: TaxReturnService) {
    this.formGroup = formBuilder.group({
      email: new FormControl("", { updateOn: "blur", validators: [Validators.email, Validators.required] }),
      password: new FormControl("", { updateOn: "change", validators: Validators.required })
    });
  }

  public async login() {
    this.notificationService.handlePromise(this.axiosApiClient.get(UsersApi).authenticateUserWithPassword({
      loginUserDto: {
        email: this.formGroup.get("email").value,
        password: this.formGroup.get("password").value
      }
    }),
      {
        errorMsg: "Bei der Anmeldung ist ein Fehler passiert!",
        successMsg: "Erfolgreich eingeloggt"
      }).then(async (response) => {
        this.tokenService.setLoginToken(response.data?.token);
        currentUser$$.setLoading().setValue((await this.axiosApiClient.get(UsersApi).getMyUser()).data);
        this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(currentTaxReturn$$.value), {
          successMsg: "Steuererklärung erfolgreich geladen",
          errorMsg: "Beim Laden der Steuererklärung ist etwas schief gegangen. Wähle unter Home dein gewünschtes Jahr!"
        });
        this.router.navigate(["/home"]);
      });
  }

}
