<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3"
        src="/assets/icons/sonderausgaben_icon.svg" />
</div>
<mat-toolbar class="top-toolbar red">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/sonderausgaben_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Sonderausgaben/außergewöhnliche Belastungen</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Zu den außergewöhnlichen Belastungen gehören u.a. Versicherungsbeiträge, Krankheitskosten, Unfall, Haftpflicht etc.</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper red-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>

                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.healthInsuranceDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Krankenkassenbeiträge</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.healthInsuranceFee">{{taxreturn.data.subjectAreaSpecialCosts.healthInsuranceFee | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.healthInsuranceDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.healthInsuranceDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.healthInsuranceDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-label>Bescheinigung der Krankenkasse über bezahlte und erstattete Beiträge zur Kranken- und
                            Pflegeversicherung (sofern nicht bereits auf der Lohnsteuerbescheinigung ausgewiesen)
                        </mat-label>
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <input matInput name="healthInsuranceFee"
                                [(ngModel)]="taxreturn.data.subjectAreaSpecialCosts.healthInsuranceFee" type="number"
                                placeholder="monatlicher Beitrag in Euro" optional>
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('healthInsuranceDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Bescheinigung hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.healthInsuranceDocs" subjectArea="subjectAreaSpecialCosts" option="healthInsuranceDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.contributionPaymentsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Personenversicherungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.contributionPaymentsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.contributionPaymentsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.contributionPaymentsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Angaben zu gezahlten Personenversicherungen (Lebens-, Haftpflicht-,
                            Berufsunfähigkeit-, Unfall-, privaten Krankenzusatzversicherung, etc.) </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('contributionPaymentsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Beitragsbeleg hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.contributionPaymentsDocs" subjectArea="subjectAreaSpecialCosts" option="contributionPaymentsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.privatePensionInsuranceDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Rentenversicherungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.privatePensionInsuranceDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.privatePensionInsuranceDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.privatePensionInsuranceDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Bescheinigung über private Rentenversicherung („Rürup-Rente“, „Riester-Rente“)
                        </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent"
                                (click)="uploadFile('privatePensionInsuranceDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Beitragsbeleg hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.privatePensionInsuranceDocs" subjectArea="subjectAreaSpecialCosts" option="privatePensionInsuranceDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.donationDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Spendenbescheinigungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.donationDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.donationDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.donationDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Bescheinigung Ihrer Spenden</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('donationDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweis hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.donationDocs" subjectArea="subjectAreaSpecialCosts" option="donationDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.politicalPartyFeesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Zuwendungsbestätigungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.politicalPartyFeesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.politicalPartyFeesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.politicalPartyFeesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Mitgliedsbeiträge an politische Parteien</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('politicalPartyFeesDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweis hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.politicalPartyFeesDocs" subjectArea="subjectAreaSpecialCosts" option="politicalPartyFeesDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.physicalDisabilitiesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Körperbehinderungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.physicalDisabilitiesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.physicalDisabilitiesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.physicalDisabilitiesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über etwaige Körperbehinderungen</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('physicalDisabilitiesDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweis hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.physicalDisabilitiesDocs" subjectArea="subjectAreaSpecialCosts" option="physicalDisabilitiesDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.churchTaxDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Kirchensteuerbescheide</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.churchTaxDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.churchTaxDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.churchTaxDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>falls nicht schon auf der Lohnsteuerbescheinigung angegeben</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('churchTaxDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Bescheide hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.churchTaxDocs" subjectArea="subjectAreaSpecialCosts" option="churchTaxDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.medicalExpensesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Krankheitskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.medicalExpensesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.medicalExpensesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.medicalExpensesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Krankheitskosten (Arzt, Zahnarzt, Krankenhaus, Pflegeheim, Kurkosten,
                            Medikamente, etc.)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('medicalExpensesDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweise hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.medicalExpensesDocs" subjectArea="subjectAreaSpecialCosts" option="medicalExpensesDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.coPaymentsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Zuzahlungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.coPaymentsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.coPaymentsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.coPaymentsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Zuzahlungen bei Zahnersatz, Brillen, Hörgeräten, etc.</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('coPaymentsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.coPaymentsDocs" subjectArea="subjectAreaSpecialCosts" option="coPaymentsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.careAllowanceDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Unterhaltsleistungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.careAllowanceDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.careAllowanceDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.careAllowanceDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Belege über Unterstützung von Angehörigen oder über die Zahlung von Unterhaltsleistungen an geschiedene Ehegatten.</mat-label>
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('careAllowanceDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                            <mat-hint class="mat-caption" fxFlexAlign="start">Falls ja: Nachweise über das eigenen
                                Einkommen der unterstützten
                                Angehörigen (z. B. Renten, Arbeitslohn, etc.)</mat-hint>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.careAllowanceDocs" subjectArea="subjectAreaSpecialCosts" option="careAllowanceDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaSpecialCosts.funeralExpensesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Beerdigungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaSpecialCosts.funeralExpensesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaSpecialCosts.funeralExpensesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaSpecialCosts.funeralExpensesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="center stretch">
                        <button mat-stroked-button color="accent" (click)="uploadFile('funeralExpensesDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Kostenrechnung hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaSpecialCosts?.funeralExpensesDocs" subjectArea="subjectAreaSpecialCosts" option="funeralExpensesDocs">
                    </uploaded-file-list>
                </mat-step>
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaSpecialCosts.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>
</div>