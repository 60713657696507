import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as Rellax from "rellax";
import { ChooseFileDialogComponent } from "src/app/components/choose-file-dialog/choose-file-dialog.component";


@Component({
  templateUrl: "./rentner.component.html",
  styleUrls: ["./rentner.component.scss"],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class RentnerComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  public ngOnInit() {
    (window as any).rellax = new Rellax(".rellax");

  }

  public uploadFile(entity: string) {
    this.dialog.open(ChooseFileDialogComponent, { data: { path: "subjectAreaPensioner." + entity } });
  }
}
