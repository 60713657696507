import { Component, OnInit } from "@angular/core";
import { Store } from "@smallstack/store";
import { Subscription } from "rxjs";
import { currentTaxReturn$$, currentTaxYear$, currentUser$$, myTaxReturns$$ } from "src/app/app.state";
import { TaxReturn } from "src/app/models/tax-return.entity";
import { TaxReturnService } from "src/app/services/tax-return.service";

@Component({
  templateUrl: "./ablage.component.html",
  styleUrls: ["./ablage.component.scss"]
})
export class AblageComponent implements OnInit {

  public currentTaxYear: number;
  public currentTaxReturn: TaxReturn;
  public currentUser$$: Store = currentUser$$;
  public myTaxReturns: Store<TaxReturn[]> = myTaxReturns$$;

  private sub: Subscription = new Subscription();

  constructor(private taxReturnService: TaxReturnService) { }

  public ngOnInit(): void {
    this.sub.add(currentTaxYear$.subscribe((cty) => this.currentTaxYear = cty));
    this.sub.add(currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr));
    myTaxReturns$$.setLoading().setValue(this.taxReturnService.getMyTaxReturns());
  }

  public ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

}
