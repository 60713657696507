import { Component } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { APP_DATE_FORMATS, AppDateAdapter } from "src/app/functions/format-datepicker";

@Component({
  templateUrl: "./stammdaten.component.html",
  styleUrls: ["./stammdaten.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class StammdatenComponent {

  public panelOpenState = false;
  public datePicker: MatDatepicker<Date>;


}
