<div class="page">
    <div class="login-container" fxLayout="column" fxLayoutAlign="center stretch" fxFlexAlign="center">
        <div *ngIf="currentPage === ShowPage.EnterMail" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between stretch" class="login-box">
            <div fxLayout="column" fxLayoutAlign="space-evenly stretch" fxLayoutAlign.lt-md="start stretch" fxFlex="55"
                fxFlex.lt-md="65" fxFlex.xs="75">
                <div fxLayout="column" fxFlexAlign="center" style="padding: 20px 20px 0px 20px;">
                    <img src="assets/icons/logo.svg" alt="logo" style="width: 120px;">
                    <h1 class="mat-subtitle" style="margin: 0px;">PASSWORT VERGESSEN?</h1>
                    <p class="mat-body-1" style="margin-top: 0px;">Kein Problem. Gib hier einfach deine E-Mail Adresse
                        ein, mit der du dich registriert hast und wir senden dir einen Link, mit dem du dein Passwort
                        zurücksetzen kannst!</p>
                </div>
                <form [formGroup]="emailFormGroup" fxLayout="column" fxLayoutGap="10px" style="padding: 0px 20px 20px;">
                    <mat-form-field>
                        <mat-label>E-Mail</mat-label>
                        <input matInput placeholder="bea@taxgirl.de" type="email" required class="form-control"
                            formControlName="email" name="email" [(ngModel)]="email">
                        <mat-error *ngIf="emailFormGroup.get('email').hasError('required')">Bitte gib deine E-Mail
                            Adresse ein!
                        </mat-error>
                        <mat-error *ngIf="emailFormGroup.get('email').hasError('email')">Das Format der eingegebenen
                            E-Mail Adresse ist
                            inkorrekt!</mat-error>
                    </mat-form-field>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin: 20px 0px;">
                        <button mat-raised-button color="primary" (click)="sendPasswordResetMail()"
                            fxFlex="45">Senden</button>
                        <a [routerLink]="['../login']" mat-stroked-button color="accent" fxFlex="45">zurück zum
                            Login</a>
                    </div>
                </form>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="45" fxFlex.lt-md="35" fxFlex.xs="25"
                class="office">
                <img src="assets/images/bea-sad.png" alt="Bea traurig" class="bea-sad-image">
            </div>
        </div>


        <div *ngIf="currentPage === ShowPage.CheckMailbox" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between stretch" class="login-box">
            <div fxLayout="column" fxLayoutAlign="space-evenly stretch" fxLayoutAlign.lt-md="start stretch" fxFlex="55"
                fxFlex.lt-md="65" fxFlex.xs="75">
                <div fxLayout="column" fxFlexAlign="center" style="padding: 20px 20px 0px 20px;">
                    <img src="assets/icons/logo.svg" alt="logo" style="width: 120px;">
                    <h1 class="mat-subtitle" style="margin: 0px;">MAIL IST UNTERWEGS</h1>
                    <p class="mat-body-1" style="margin-top: 0px;">Bitte prüfe dein E-Mail Postfach auf neue Mails von
                        mir! Darin befindet sich der Link zum Zurücksetzen deines Passwortes.</p>
                    <a [routerLink]="['../login']" mat-stroked-button color="accent" fxFlex="45"
                        style="margin-top: 20px;">zurück zum
                        Login</a>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="45" fxFlex.lt-md="35" fxFlex.xs="25"
                class="office">
                <img src="assets/images/bea-faust.png" alt="Bea traurig" class="bea-sad-image">
            </div>
        </div>


        <div *ngIf="currentPage === ShowPage.EnterNewPassword" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutAlign="space-between stretch" class="login-box">
            <div fxLayout="column" fxLayoutAlign="space-evenly stretch" fxLayoutAlign.lt-md="start stretch" fxFlex="55"
                fxFlex.lt-md="65" fxFlex.xs="75">
                <div fxLayout="column" fxFlexAlign="center" style="padding: 20px 20px 0px 20px;">
                    <img src="assets/icons/logo.svg" alt="logo" style="width: 120px;">
                    <h1 class="mat-subtitle" style="margin: 0px;">PASSWORT ZURÜCKSETZEN</h1>
                    <p class="mat-body-1" style="margin-top: 0px;">Du bist hier, weil du in der E-Mail auf den Link zum
                        Zurücksetzen deines Passwortes geklickt hast.</p>
                </div>
                <form [formGroup]="passwordFormGroup"  fxLayout="column" fxLayoutGap="40px"
                    style="padding: 0px 20px 20px;">
                    <mat-form-field>
                        <mat-label>Passwort</mat-label>
                        <input matInput required minlength="8" class="form-control" formControlName="password"
                            name="password" [(ngModel)]="password" [type]="hidePassword ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hidePassword = !hidePassword" style="cursor: pointer">
                            {{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint>Das Passwort muss aus min. 8 Zeichen bestehen, min. einen Großbuchstaben, eine Zahl
                            und ein Sonderzeichen enthalten.</mat-hint>
                        <mat-error *ngIf="passwordFormGroup.get('password').hasError('required')">Bitte gid dein
                            Passwort ein!
                        </mat-error>
                    </mat-form-field>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin: 20px 0px;">
                        <button mat-raised-button [disabled]="passwordFormGroup.invalid" fxFlex="45" color="primary"
                            (click)="changePassword()">Passwort ändern</button>
                        <a [routerLink]="['../login']" mat-stroked-button color="accent" fxFlex="45">zurück zum
                            Login</a>
                    </div>
                </form>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="45" fxFlex.lt-md="35" fxFlex.xs="25"
                class="office">
                <img src="assets/images/bea-faust.png" alt="Bea traurig" class="bea-sad-image">
            </div>
        </div>
    </div>
</div>