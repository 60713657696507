<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3" src="/assets/icons/sparer_icon.svg" />
</div>
<mat-toolbar class="top-toolbar orange">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/sparer_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Sparer/Anleger</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Nachweis über Einkünfte aus Kapitalvermögen</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper orange-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>
                <mat-step [completed]="taxreturn.data.subjectAreaInvestor.capitalAssetsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Einkünfte aus Kapitalvermögen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaInvestor.capitalAssetsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaInvestor.capitalAssetsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaInvestor.capitalAssetsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweis über Einkünfte aus Kapitalvermögen, z. B. Zinsen/Dividenden, Steuerbescheinigungen der Bank, Bausparkasse, etc.</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('capitalAssetsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweise hochladen
                            </button>
                            <mat-hint class="mat-caption">Sofern die Bank Abgeltungsteuer einbehalten hat oder die
                                Einkünfte
                                über der Freistellungsgrenze liegen, lohnt sich eine Überprüfung</mat-hint>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaInvestor?.capitalAssetsDocs" subjectArea="subjectAreaInvestor" option="capitalAssetsDocs">
                    </uploaded-file-list>
                </mat-step>
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaInvestor.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>

</div>