<mat-toolbar class="top-toolbar purple">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <span class="mat-title" style="margin: 0px;">Ablage</span>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row">
            <div class="top right speech-bubble" fxFlex="65">
                <p class="mat-subtitle bea-talks">In deiner Ablage findest du alle deine laufenden
                    Steuererklärungen und deine hochgeladenen Dokumente ohne Zuordnung zu einem Themenbereich.</p>
            </div>
            <div class="bea welcome" fxFlex="34">
            </div>
        </div>
    </div>
    <store-container [store]="myTaxReturns">
        <ng-container *ngIf="myTaxReturns.value$ | async as myTaxReturns">
            <div class="ablage">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let taxReturn of myTaxReturns">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3 class="mat-subtitle">Ablage Steuererklärung {{taxReturn.year}}</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <uploaded-file-list [docs]="taxReturn.data.general.filing" [showInFiling]="true">
                        </uploaded-file-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-container>
    </store-container>
</div>