export enum SubjectAreas {
  "children" = "Kinder",
  "specialCosts" = "Sonderausgaben / außergewöhnliche Belastungen",
  "worker" = "Arbeitnehmer / Werbungskosten",
  "properties" = "Immobilien / Mieter",
  "investor" = "Sparer / Anleger",
  "pensioner" = "Rentner"
}

export const subjectAreas: { [key: string]: string } = { children: "Kinder", specialCosts: "Sonderausgaben / außergewöhnliche Belastungen", worker: "Arbeitnehmer / Werbungskosten", properties: "Immobilien / Mieter", investor: "Sparer / Anleger", pensioner: "Rentner" };
export const subjectAreaChildren: { [key: string]: string } = { childBenefitDocs: "Kindergeld", childCareDocs: "Kinderbetreuungskosten", childAlimonyDocs: "Unterhaltsleistungen", foreignLodgingDocs: "Auswärtige Unterbringung", trainingCertificateDocs: "Ausbildungsnachweise bei Kindern ab 18 Jahre", disabilityDocs: "Behinderungen", childHealthInsuranceContributionDocs: "Krankenversicherungsbeiträge Ihrer Kinder" };
export const subjectAreaSpecialCosts: { [key: string]: string } = { healthInsuranceDocs: "Krankenkassenbeiträge", contributionPaymentsDocs: "Personenversicherungen", privatePensionInsuranceDocs: "Rentenversicherungen", donationDocs: "Spendenbescheinigungen", politicalPartyFeesDocs: "Zuwendungsbestätigungen", physicalDisabilitiesDocs: "Körperbehinderungen", churchTaxDocs: "Kirchensteuerbescheide", medicalExpensesDocs: "Krankheitskosten", coPaymentsDocs: "Zuzahlungen", careAllowanceDocs: "Unterhaltsleistungen", funeralExpensesDocs: "Beerdigungskosten" };
export const subjectAreaWorker: { [key: string]: string } = { workEquipmentCostDocs: "Kosten für Arbeitsmittel", trainingCostsDocs: "Fortbildungskosten", applicationCostsDocs: "Bewerbungskosten", missionExpensesDocs: "Reisekosten", unionContributionDocs: "Beruf/Gewerkschaft", taxAdviceCostsDocs: "Steuerberatungskosten", accidentCostsDocs: "Unfallkosten", privateAccidentCostsDocs: "Private Unfall- und Rechtsschutz", workSpaceCostsDocs: "Kosten für ein Arbeitszimmer", doubleHousingCostsDocs: "Kosten für doppelte Haushaltsführung" };
export const subjectAreaProperties: { [key: string]: string } = { servicesDocs: "Dienstleistungen / Handwerkerleistungen", employmentRelationshipsDocs: "Beschäftigungsverhältnisse", rentalIncomeDocs: "Vermietung und Verpachtung", operatingCostDocs: "Betriebskosten", homeownersAssociationAnnualStatementDocs: "Jahresabrechnung" };
export const subjectAreaInvestor: { [key: string]: string } = { capitalAssetsDocs: "Einkünfte aus Kapitalvermögen" };
export const subjectAreaPensioner: { [key: string]: string } = { pensionInsuranceDocs: "Rentenversicherung", retirementProvisionDocs: "Altersvorsorge", foreignIncomeDocs: "Ausländische Einkünfte", alimonyDocs: "Unterhaltsleistungen", salesTransactionsDocs: "Veräußerungsgeschäfte", mediationAndRentalDocs: "Vermittlungen/Vermietung", compensationAndGrantsDocs: "Entschädigungen/Zuschüsse", pensionPaymentsDocs: "Versorgungsbezüge", educatorIncomeDocs: "Ausbilder/Erzieher", businessAndParticipationIncomeDocs: "Gewerbebetrieb/Beteiligungen", independentWorkIncomeDocs: "Selbständige Arbeit" };


export enum TaxReturnStatus {
  processing = "in Bearbeitung",
  submitted = "Erklärung eingereicht",
  completed = "Erklärung abgeschlossen"
}

export interface TaxReturnDocument {
  id: string;
  url: string;
  fileSize: number;
  type: string;
  name: string;
}

export interface TaxReturn {
  id?: string;
  ownerId: string;
  year: number;
  status?: TaxReturnStatus;
  emailCommunicationAccepted: boolean;
  invoiceViaEmail: boolean;
  profile?: {
    title?: string;
    firstName?: string;
    lastName?: string;
    street?: string;
    zip?: number;
    city?: string;
    birthday?: string;
    email?: string;
    phone?: string;
    denomination?: string;
    paysChurchTax?: boolean;
    iban?: string;
    maritalStatus?: string;
    nameOfPartner?: string;
    birthdayOfPartner?: string;
    taxIdOfPartner?: string;
    addressOfPartner?: string;
    dateOfMarriage?: string;
    dateOfDivorce?: string;
    taxId?: string;
    taxNumber?: string;
    lastTaxAssessmentAvailable?: boolean;
    alreadySubmittedATax?: boolean;
  };
  data: {
    general?: {
      filing?: TaxReturnDocument[];
    };
    subjectAreaWorkers?: {
      isActive?: boolean;
      electronicEmploymentTaxCertificate?: TaxReturnDocument[];
      notificationOfAbsenteePayments?: TaxReturnDocument[];
      distanceToOffice?: number;
      numberOfDaysDrivenToWork?: number;
      meansOfTransport?: string;
      workEquipmentCostDocs?: TaxReturnDocument[];
      trainingCostsDocs?: TaxReturnDocument[];
      applicationCostsDocs?: TaxReturnDocument[];
      missionExpensesDocs?: TaxReturnDocument[];
      unionContributionDocs?: TaxReturnDocument[];
      taxAdviceCostsDocs?: TaxReturnDocument[];
      accidentCostsDocs?: TaxReturnDocument[];
      privateAccidentCostsDocs?: TaxReturnDocument[];
      workSpaceCosts?: number;
      workSpaceCostsDocs?: TaxReturnDocument[];
      doubleHousingCosts?: number;
      doubleHousingCostsDocs?: TaxReturnDocument[];
      hints?: string;
    };
    subjectAreaProperties?: {
      isActive?: boolean;
      servicesDocs?: TaxReturnDocument[];
      employmentRelationshipsDocs?: TaxReturnDocument[];
      rentalIncomeDocs?: TaxReturnDocument[];
      operatingCostDocs?: TaxReturnDocument[];
      homeownersAssociationAnnualStatementDocs?: TaxReturnDocument[];
      acquisitionCostDocs?: TaxReturnDocument[];
      financingCostDocs?: TaxReturnDocument[];
      movingCostDocs?: TaxReturnDocument[];
      hints?: string;
    };
    subjectAreaChildren?: {
      isActive?: boolean;
      numberOfChildren?: number;
      namesOfChildren?: string;
      birthdatesOfChildren?: string;
      taxIdsOfChildren?: string;
      addressOfChildren?: string;
      parentalAllowanceDocs?: TaxReturnDocument[];
      childBenefit?: number;
      childBenefitDocs?: TaxReturnDocument[];
      childCareCosts?: number;
      childCareDocs?: TaxReturnDocument[];
      childAlimony?: number;
      childAlimonyDocs?: TaxReturnDocument[];
      foreignLodgingDocs?: TaxReturnDocument[];
      trainingCertificateDocs?: TaxReturnDocument[];
      disabilityDocs?: TaxReturnDocument[];
      childHealthInsuranceContributionDocs?: TaxReturnDocument[];
      hints?: string;
    };
    subjectAreaPensioner?: {
      isActive?: boolean;
      pensionInsuranceDocs?: TaxReturnDocument[];
      retirementProvisionDocs?: TaxReturnDocument[];
      foreignIncomeDocs?: TaxReturnDocument[];
      alimonyDocs?: TaxReturnDocument[];
      salesTransactionsDocs?: TaxReturnDocument[];
      mediationAndRentalDocs?: TaxReturnDocument[];
      compensationAndGrantsDocs?: TaxReturnDocument[];
      pensionPaymentsDocs?: TaxReturnDocument[];
      educatorIncomeDocs?: TaxReturnDocument[];
      businessAndParticipationIncomeDocs?: TaxReturnDocument[];
      independentWorkIncomeDocs?: TaxReturnDocument[];
      hints?: string;
    };
    subjectAreaSpecialCosts?: {
      isActive?: boolean;
      contributionPaymentsDocs?: TaxReturnDocument[];
      healthInsuranceFee?: number;
      healthInsuranceDocs?: TaxReturnDocument[];
      privatePensionInsuranceDocs?: TaxReturnDocument[];
      donationDocs?: TaxReturnDocument[];
      politicalPartyFeesDocs?: TaxReturnDocument[];
      physicalDisabilitiesDocs?: TaxReturnDocument[];
      churchTaxDocs?: TaxReturnDocument[];
      medicalExpensesDocs?: TaxReturnDocument[];
      coPaymentsDocs?: TaxReturnDocument[];
      careAllowanceDocs?: TaxReturnDocument[];
      funeralExpensesDocs?: TaxReturnDocument[];
      hints?: string;
    };
    subjectAreaInvestor?: {
      isActive?: boolean;
      capitalAssetsDocs?: TaxReturnDocument[];
      hints?: string;
    }
  };
}
