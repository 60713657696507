<div class="container">
    <div class="home page-header" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="40px">
        <img src="assets/icons/logo.svg" class="logo">
        <div class="top right speech-bubble" style="max-width: 45%;">

            <p class="mat-subtitle bea-talks">Hallo
                <store-container [store]="currentUser$$">
                    <ng-container *ngIf="currentUser$$.value$ | async as currentUser">
                        <span style="font-weight: bold;">{{currentUser.displayName}},</span>
                    </ng-container>
                </store-container>
                <br>willkommen in meinem Home-Office. Ich bin Bea, dein Taxgirl, und möchte
                dir gerne bei deiner Steuererklärung helfen. Lass uns am besten direkt loslegen. Du wirst sehen,
                mit meiner Hilfe geht das ganz schnell.</p>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
        <div *ngIf="currentTaxYear">
            <h3 class="mat-subtitle">Derzeit in Erfassung</h3>
            <p>Du hast bereits mit einer Steuererklärung angefangen. Du kannst für diese weiter Daten erfassen, sie
                einreichen oder auf dessen Basis eine neue Steuererklärung beginnen.</p>
            <div class="card-box">
                <div class="content">
                    <mat-card-title>
                        Steuererklärung {{currentTaxYear}}
                    </mat-card-title>
                    <p class="mat-caption">
                        <mat-card-subtitle *ngIf="currentTaxReturn">
                            Status: {{currentTaxReturn.status}}
                        </mat-card-subtitle>
                    </p>
                    <!-- <p class="mat-caption">
                            <mat-card-subtitle>
                                Fortschritt: 69%
                            </mat-card-subtitle>
                            <br>7 Werte erfasst / 13 Dokumente hochgeladen
                        </p> -->
                </div>
                <div class="actions" align="end">
                    <button mat-stroked-button (click)="switchTaxYear($event, currentTaxYear)">
                        Bearbeiten
                    </button>
                    <button mat-stroked-button disabled="true">
                        Duplizieren
                    </button>
                    <button mat-stroked-button [routerLink]="['/einreichen']">
                        Einreichen
                    </button>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center">
            <h3 class="mat-subtitle">Neue Steuererklärung starten</h3>
            <button mat-stroked-button class="create-tax-button" color="primary" #ddTrigger="matMenuTrigger"
                [matMenuTriggerFor]="dd">Jahr
                auswählen&nbsp;
                <mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #dd="matMenu" [overlapTrigger]="false">
                <div class="dropdown">
                    <ng-container *ngFor="let year of years">
                        <button *ngIf="year !== currentTaxYear" mat-menu-item (click)="switchTaxYear($event, year)"
                            [value]="year">{{year}}</button>
                    </ng-container>
                </div>
            </mat-menu>
        </div>
    </div>
</div>