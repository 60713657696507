<mat-toolbar class="top-toolbar purple">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <span class="mat-title" style="margin: 0px;">Stammdaten</span>
        <settings-button></settings-button>
    </div>
</mat-toolbar>
<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Hier kannst du sämtliche Angaben zu Deinen persönlichen Daten pro Steuererklärung pflegen.</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Anrede</h3>
                            <p>Titel, Vorname, Nachname</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" *ngIf="taxreturn.profile?.firstName || taxreturn.profile?.lastName" fxLayout="row"
                                fxLayoutAlign="end center" style="width: 100%;">
                                <span>{{taxreturn.profile?.title}}&nbsp;</span>
                                <span>{{taxreturn.profile?.firstName}}&nbsp;</span>
                                <span>{{taxreturn.profile?.lastName}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-form-field fxFlex="20">
                                <input matInput placeholder="Titel" name="titel" [(ngModel)]="taxreturn.profile.title">
                            </mat-form-field>
                            <mat-form-field fxFlex="37">
                                <input matInput placeholder="Vorname" name="vorname"
                                    [(ngModel)]="taxreturn.profile.firstName">
                            </mat-form-field>
                            <mat-form-field fxFlex="37">
                                <input matInput placeholder="Nachname" name="nachname"
                                    [(ngModel)]="taxreturn.profile.lastName">
                            </mat-form-field>
                        </div>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Adresse</h3>
                            <p>Straße & Hausnummer, PLZ Ort</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" *ngIf="taxreturn.profile.street" fxLayout="row wrap"
                                fxLayoutAlign="end center" style="width: 100%;">
                                <span>{{taxreturn.profile?.street}}</span>
                                <span *ngIf="taxreturn.profile?.zip">,&nbsp;{{taxreturn.profile?.zip}}</span>
                                <span *ngIf="taxreturn.profile?.city">&nbsp;{{taxreturn.profile?.city}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-form-field fxFlex="100">
                                <input matInput name="strasse" placeholder="Straße/Hausnummer"
                                    [(ngModel)]="taxreturn.profile.street">
                            </mat-form-field>
                            <mat-form-field fxFlex="25">
                                <input matInput name="plz" placeholder="PLZ" [(ngModel)]="taxreturn.profile.zip">
                            </mat-form-field>
                            <mat-form-field fxFlex="70">
                                <input matInput name="ort" placeholder="Ort" [(ngModel)]="taxreturn.profile.city">
                            </mat-form-field>
                        </div>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Geburtstag</h3>
                            <p>Datum deiner Geburt</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" *ngIf="taxreturn.profile.birthday" fxLayout="row"
                                fxLayoutAlign="end center" style="width: 100%;">
                                <span>{{taxreturn.profile?.birthday | date:'dd.MM.yyyy'}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <mat-form-field (click)="datepickerRef.open()">
                            <input matInput placeholder="Wähle ein Datum" [matDatepicker]="datepickerRef"
                                name="datepicker" [(ngModel)]="taxreturn.profile.birthday" readonly />
                            <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker startView="multi-year" #datepickerRef></mat-datepicker>
                        </mat-form-field>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Kontakt-Daten</h3>
                            <p>E-Mail, Telefon</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" fxLayout="row wrap" fxLayoutAlign="end center"
                                style="width: 100%;">
                                <span *ngIf="taxreturn.profile.email">{{taxreturn.profile.email}}</span>
                                <span *ngIf="taxreturn.profile.phone">&nbsp;/
                                    {{taxreturn.profile.phone}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <mat-form-field>
                            <input matInput type="email" name="email" placeholder="E-Mail" [(ngModel)]="taxreturn.profile.email" email>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="telefon" placeholder="Telefon"
                                [(ngModel)]="taxreturn.profile.phone">
                        </mat-form-field>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Religion/Kirche</h3>
                            <p>Konfession, Kirchen-Steuer</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" fxLayout="row" fxLayoutAlign="end center" style="width: 100%;">
                                <span *ngIf="taxreturn.profile.denomination"
                                    fxLayoutAlign="center">{{taxreturn.profile.denomination}}</span>
                                <span *ngIf="taxreturn.profile.paysChurchTax" fxLayoutAlign="center">&nbsp;/&nbsp;Kirche
                                    <mat-icon class="check-icon">check</mat-icon></span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px"
                        style="padding-bottom: 20px;">
                        <mat-form-field fxFlex="80">
                            <input matInput name="denomination" placeholder="Religionszugehörigkeit"
                                [(ngModel)]="taxreturn.profile.denomination">
                        </mat-form-field>
                        <mat-checkbox class="mat-caption" [checked]="taxreturn.profile.paysChurchTax"
                            (change)="taxreturn.profile.paysChurchTax = $event.checked">Kirchen-Steuer?</mat-checkbox>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Bankdaten</h3>
                            <p>IBAN</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" fxLayout="row wrap" fxLayoutAlign="end center"
                                style="width: 100%;">
                                <span *ngIf="taxreturn.profile.iban">{{taxreturn.profile.iban}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <mat-form-field>
                            <input matInput name="iban" placeholder="IBAN" [(ngModel)]="taxreturn.profile.iban">
                        </mat-form-field>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Familie</h3>
                            <p>Familienstand</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" fxLayout="row wrap" fxLayoutAlign="end center"
                                style="width: 100%;">
                                <span *ngIf="taxreturn.profile.maritalStatus">{{taxreturn.profile.maritalStatus}}</span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" style="padding-bottom: 20px;">
                        <mat-form-field>
                            <mat-label>Familienstand</mat-label>
                            <mat-select [(ngModel)]="taxreturn.profile.maritalStatus" name="familienstand">
                                <mat-option value="ledig">ledig</mat-option>
                                <mat-option value="verheiratet">verheiratet</mat-option>
                                <mat-option value="verpartnert">eingetragene Lebenspartnerschaft</mat-option>
                                <mat-option value="geschieden">geschieden</mat-option>
                                <mat-option value="verwitwet">verwitwet</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="taxreturn.profile.maritalStatus === 'verheiratet' || taxreturn.profile.maritalStatus === 'verpartnert'">
                            <mat-form-field fxFlex="48">
                                <mat-label>Name {{taxreturn.profile.maritalStatus === 'verheiratet' ? 'Ehegatten' : 'Lebenspartner'}}</mat-label>
                                <input matInput name="nameOfPartner" placeholder=""
                                    [(ngModel)]="taxreturn.profile.nameOfPartner">
                            </mat-form-field>
                            <mat-form-field fxFlex="48" (click)="datepickerRef.open()">
                                <mat-label>Geburtsdatum {{taxreturn.profile.maritalStatus === 'verheiratet' ? 'Ehegatten' : 'Lebenspartner'}}</mat-label>
                                <input matInput [matDatepicker]="datepickerRef"
                                    name="birthdayOfPartner" [(ngModel)]="taxreturn.profile.birthdayOfPartner" readonly />
                                <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker startView="multi-year" #datepickerRef></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field fxFlex="48">
                                <mat-label>Steuer-ID {{taxreturn.profile.maritalStatus === 'verheiratet' ? 'Ehegatten' : 'Lebenspartners'}}</mat-label>
                                <input matInput name="taxIdOfPartner" placeholder=""
                                    [(ngModel)]="taxreturn.profile.taxIdOfPartner">
                            </mat-form-field>
                            <mat-form-field fxFlex="48">
                                <mat-label>Adresse {{taxreturn.profile.maritalStatus === 'verheiratet' ? 'Ehegatten' : 'Lebenspartners'}} (sofern abweichend)</mat-label>
                                <input matInput name="addressOfPartner" placeholder=""
                                    [(ngModel)]="taxreturn.profile.addressOfPartner">
                            </mat-form-field>
                            <mat-form-field fxFlex="48" (click)="datepickerRefe.open()">
                                <mat-label>Datum Hochzeit</mat-label>
                                <input matInput [matDatepicker]="datepickerRefe"
                                    name="dateOfMarriage" [(ngModel)]="taxreturn.profile.dateOfMarriage" readonly />
                                <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker startView="multi-year" #datepickerRefe></mat-datepicker>
                            </mat-form-field>                
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="taxreturn.profile.maritalStatus === 'geschieden' || taxreturn.profile.maritalStatus === 'verwitwet'">
                            <mat-form-field fxFill (click)="datepickerRefer.open()">
                                <mat-label>seit wann {{taxreturn.profile.maritalStatus}}</mat-label>
                                <input matInput [matDatepicker]="datepickerRefer"
                                    name="dateOfDivorce" [(ngModel)]="taxreturn.profile.dateOfDivorce" readonly />
                                <mat-datepicker-toggle [for]="datepickerRef" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker startView="multi-year" #datepickerRefer></mat-datepicker>
                            </mat-form-field>       
                        </div>
                    </form>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header [collapsedHeight]="'95px'" [expandedHeight]="'130px'">
                        <mat-panel-title fxLayout="column">
                            <h3 class="mat-subtitle">Steuer-Daten</h3>
                            <p>Steuer-ID, Steuernummer</p>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="mat-caption" fxLayout="column" fxLayoutAlign="center end" style="width: 100%;">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <span *ngIf="taxreturn.profile.taxId"><span fxHide.xs>Steuer-ID:</span> {{taxreturn.profile.taxId}}</span>
                                    <span *ngIf="taxreturn.profile.taxNumber">&nbsp;/&nbsp;<span fxHide.xs>Steuer-Nummer:</span>
                                        {{taxreturn.profile.taxNumber}}</span>
                                </div>
                                <span *ngIf="taxreturn.profile.lastTaxAssessmentAvailable" fxLayoutAlign="center"
                                    fxHide.xs>Letzter Bescheid vorhanden <mat-icon class="check-icon">check</mat-icon>
                                </span>
                                <span *ngIf="taxreturn.profile.alreadySubmittedATax" fxLayoutAlign="center"
                                    fxHide.xs>Schon mal eine Steuererklärung eingereicht <mat-icon class="check-icon">
                                        check</mat-icon>
                                </span>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form fxLayout="column" fxLayoutAlign="start stretch" style="padding-bottom: 20px;">
                        <mat-form-field>
                            <input matInput name="taxId" [(ngModel)]="taxreturn.profile.taxId"
                                placeholder="Persönliche Steuer-Identifikationsnummer">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput name="taxNumber" [(ngModel)]="taxreturn.profile.taxNumber"
                                placeholder="Finanzamt/Steuernummer">
                        </mat-form-field>
                        <mat-checkbox [checked]="taxreturn.profile.lastTaxAssessmentAvailable"
                            (change)="taxreturn.profile.lastTaxAssessmentAvailable = $event.checked"
                            class="mat-caption"> Letzter Steuerbescheid vorhanden?
                        </mat-checkbox>
                        <mat-checkbox [checked]="taxreturn.profile.alreadySubmittedATax"
                            (change)="taxreturn.profile.alreadySubmittedATax = $event.checked" class="mat-caption">
                            Bereits früher eine Steuererklärung eingereicht?</mat-checkbox>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>
    </current-tax-return-container>
</div>