import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "@smallstack/i18n-components";
import { Store } from "@smallstack/store";
import { currentTaxReturn$$ } from "src/app/app.state";
import { TaxReturn, TaxReturnDocument } from "src/app/models/tax-return.entity";
import { TaxReturnService, Upload } from "src/app/services/tax-return.service";

@Component({
  templateUrl: "./choose-file-dialog.component.html",
  styleUrls: ["./choose-file-dialog.component.scss"]
})
export class ChooseFileDialogComponent implements OnInit {

  @ViewChild("fileInput", { static: true })
  public fileInput: ElementRef;

  public currentUpload$$: Store<Upload>;
  public currentTaxReturn: TaxReturn = currentTaxReturn$$.value;

  public uploadProgress: number = undefined;
  public showFilingButton: boolean = true;
  public showFiling: boolean = false;

  constructor(public dialogRef: MatDialogRef<ChooseFileDialogComponent>, private taxReturnService: TaxReturnService, private notificationService: NotificationService, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.currentUpload$$ = taxReturnService.currentUpload$$;
    taxReturnService.currentUpload$$.value$.subscribe((upload) => this.uploadProgress = upload ? upload.progress : undefined);
    if (this.data.scan !== undefined && this.data.scan === true)
      this.showFilingButton = false;
  }

  public ngOnInit(): void {
    this.dialogRef.updateSize("400px");
    currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async change($event) {
    this.notificationService.handlePromise(this.taxReturnService.uploadDocument(currentTaxReturn$$.value.id, this.data.path, $event.target.files[0]), {
      errorMsg: "Beim Upload ist ein Fehler aufgetreten.",
      successMsg: "Datei erfolgreich hochgeladen"
    }).then(() => {
      this.dialogRef.close($event);
    }).catch((e) => {
      this.fileInput.nativeElement.value = "";
    });
  }

  public takeFromFiling() {
    this.showFiling = true;
  }

  public moveFileFromFilingToSubjectAreaOption(doc: TaxReturnDocument) {
    const subjectArea = this.data.path.split(".")[0];
    const option = this.data.path.split(".")[1];

    if (this.currentTaxReturn.data[subjectArea] === undefined)
      this.currentTaxReturn.data[subjectArea] = {};

    if (this.currentTaxReturn.data[subjectArea][option] === undefined)
      this.currentTaxReturn.data[subjectArea][option] = [];

    this.currentTaxReturn.data[subjectArea][option].push(doc);
    this.currentTaxReturn.data.general.filing.splice(this.currentTaxReturn.data.general.filing.findIndex((file) => file.id === doc.id), 1);
    this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(this.currentTaxReturn), {
      successMsg: "Dokument erfolgreich verschoben",
      errorMsg: "Verschieben fehlgeschlagen"
    });
    this.dialogRef.close();
  }
}
