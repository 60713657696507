import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AxiosApiClient, TenantDataApi } from "@smallstack/axios-api-client";
import { TokenService } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { Store, StoreState } from "@smallstack/store";
import {
  SearchByFieldMatcher, SQBuilder
} from "@smallstack/typesystem";
import { tap } from "rxjs/operators";
import config from "../../../config.json";
import { F_CUSTOM, T_TAXRETURNS } from "../app.constants";
import {
  currentTaxReturn$$,
  currentTaxYear$,
  currentUser$$
} from "../app.state";
import { TaxReturn, TaxReturnStatus } from "../models/tax-return.entity";

export interface Upload {
  file?: File;
  progress: number;
}

@Injectable()
export class TaxReturnService {
  public readonly currentUpload$$: Store<Upload> = new Store<Upload>();

  constructor(
    private axiosApiClient: AxiosApiClient,
    private tokenService: TokenService,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    // whenever someone changes the taxYear, load the correct taxReturn
    currentTaxYear$.subscribe((year) => {
      if (year) {
        localStorage.setItem("currentTaxYear", "" + year);
        currentTaxReturn$$.setLoading().setValue(this.getTaxReturn(year));
      }
    });

    // whenever a user gets logged in, load current tax year
    currentUser$$.value$.subscribe((user) => {
      if (user) {
        this.readTaxYearFromLocalStorage();
      }
    });

    // read previously stored tax year from local storage (or use last year as default)
    this.readTaxYearFromLocalStorage();
  }

  /**
   * Returns all my TaxReturns. If none was found, an error will be thrown.
   */
  public async getMyTaxReturns(): Promise<TaxReturn[]> {
    if (!this.tokenService.isTokenValid()) throw new Error("not logged in");
    const response = await this.axiosApiClient.get(TenantDataApi).getData({
      feature: F_CUSTOM,
      type: T_TAXRETURNS,
      search: SQBuilder.asString([
        {
          fieldname: "ownerId",
          value: currentUser$$.value.id,
          matcher: SearchByFieldMatcher.EQUALS,
        },
      ]),
    });
    if (response.data.totalElements >= 1) {
      // Falls Daten migriert werden müssen, gäbe es hier die Chance
      const taxReturns = response.data.elements;
      return taxReturns as any;
    } else {
      return undefined;
    }
  }

  /**
   * Returns a TaxReturn for the given year. If none was found, an empty one will get returned.
   *
   * @param year
   */
  public async getTaxReturn(year: number): Promise<TaxReturn> {
    if (!this.tokenService.isTokenValid()) throw new Error("not logged in");
    const response = await this.axiosApiClient.get(TenantDataApi).getData({
      feature: F_CUSTOM,
      type: T_TAXRETURNS,
      search: SQBuilder.asString(
        [
          {
            fieldname: "year",
            value: year,
            matcher: SearchByFieldMatcher.EQUALS,
          },
          {
            fieldname: "ownerId",
            value: currentUser$$?.value?.id,
            matcher: SearchByFieldMatcher.EQUALS,
          },
        ],
        "and"
      ),
    });
    if (response.data.totalElements >= 1) {
      // Falls Daten migriert werden müssen, gäbe es hier die Chance
      const taxReturn: TaxReturn = response.data.elements[0] as TaxReturn;
      if (taxReturn.status === undefined)
        taxReturn.status = TaxReturnStatus.processing;
      if (taxReturn.profile === undefined) taxReturn.profile = {};
      if (taxReturn.data === undefined) taxReturn.data = {};
      if (taxReturn.data.subjectAreaInvestor === undefined)
        taxReturn.data.subjectAreaInvestor = {};
      if (taxReturn.data.general === undefined) taxReturn.data.general = {};
      if (taxReturn.data.general.filing === undefined)
        taxReturn.data.general.filing = [];
      if (taxReturn.emailCommunicationAccepted === undefined)
        taxReturn.emailCommunicationAccepted = false;
      return taxReturn;
    } else {
      return {
        ownerId: currentUser$$.value.id,
        year,
        status: TaxReturnStatus.processing,
        profile: {},
        emailCommunicationAccepted: false,
        invoiceViaEmail: false,
        data: {
          general: {},
          subjectAreaChildren: { isActive: true },
          subjectAreaPensioner: { isActive: true },
          subjectAreaProperties: { isActive: true },
          subjectAreaSpecialCosts: { isActive: true },
          subjectAreaWorkers: { isActive: true },
          subjectAreaInvestor: { isActive: true },
        },
      };
    }
  }

  public async getCurrentTaxReturn(): Promise<TaxReturn> {
    return currentTaxReturn$$.value;
  }

  public async updateTaxReturn(taxReturn: TaxReturn): Promise<TaxReturn> {
    if (taxReturn?.id === undefined) {
      taxReturn = (
        await this.axiosApiClient
          .get(TenantDataApi)
          .createData({
            anyBody: taxReturn,
            feature: F_CUSTOM,
            type: T_TAXRETURNS,
          })
      ).data as any;
    } else
      taxReturn = (
        await this.axiosApiClient
          .get(TenantDataApi)
          .putData({
            id: taxReturn.id,
            anyBody: taxReturn,
            feature: F_CUSTOM,
            type: T_TAXRETURNS,
          })
      ).data as any;
    currentTaxReturn$$.setValue(taxReturn);
    return taxReturn;
  }

  public async uploadDocument(
    tid: string,
    path: string,
    file: File
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        this.currentUpload$$.setLoading();
        this.currentUpload$$.setValue({ file, progress: 0 }, false);
        if (file.type === undefined || file.type.length < 3)
          throw new Error(
            "File Type must be longer than 3 characters, but got " + file.type
          );
        const req = new HttpRequest(
          "POST",
          `${config.functions.docUploadUrl}?name=${
            file.name
          }&type=${encodeURIComponent(file.type)}&size=${
            file.size
          }&tid=${tid}&path=${path}`,
          file,
          {
            headers: new HttpHeaders({
              "x-token": this.tokenService.getLoginToken(),
            }),
            reportProgress: true,
            responseType: "text",
          }
        );
        this.http
          .request(req)
          .pipe(
            tap((e: HttpEvent<any>) => {
              if (e.type === HttpEventType.UploadProgress) {
                this.currentUpload$$.updateValue((currentUpload) => {
                  currentUpload.progress = Math.ceil(
                    (e.loaded / e.total) * 100
                  );
                  return currentUpload;
                }, false);
              } else if (e.type === HttpEventType.Response) {
                this.currentUpload$$.setValue(undefined, false);
                this.currentUpload$$.setState(StoreState.INITIAL);
                currentTaxReturn$$
                  .setLoading()
                  .setValue(this.getTaxReturn(currentTaxYear$.value));
                return resolve(e.body);
              } else if (
                e.type === HttpEventType.ResponseHeader &&
                e.status >= 400
              ) {
                this.currentUpload$$.setValue(undefined, false);
                this.currentUpload$$.setState(StoreState.INITIAL);
                return reject(e);
              }
            })
          )
          .subscribe();
      } catch (e) {
        this.notificationService.showStandardErrorPopup(e);
        this.currentUpload$$.setValue(undefined, false);
        this.currentUpload$$.setState(StoreState.ERROR);
        reject(e);
      }
    });
  }

  private readTaxYearFromLocalStorage() {
    let currentTaxYear: number = new Date().getFullYear() - 1;
    const localStorageYear = localStorage.getItem("currentTaxYear");
    if (localStorageYear !== null) currentTaxYear = parseInt(localStorageYear);
    currentTaxYear$.next(currentTaxYear);
  }
}
