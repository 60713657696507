import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { TokenService } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { isValidEmail } from "@smallstack/utils";
import jwtDecode from "jwt-decode";

enum ShowPage {
  EnterMail,
  CheckMailbox,
  EnterNewPassword,
}

@Component({
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordResetComponent {
  public ShowPage = ShowPage;
  public currentPage = ShowPage.EnterMail;

  public error: string;
  public email?: string;
  public showTokenInput: boolean;
  public password?: string;

  @Input()
  public resetToken: string;

  @Output()
  public newPassword: EventEmitter<string> = new EventEmitter();

  @Output()
  public resetPasswordMail: EventEmitter<string> = new EventEmitter();
  public emailFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;
  public hidePassword = true;

  constructor(
    formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private tokenService: TokenService,
    private axiosApiClient: AxiosApiClient
  ) {
    activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.token !== undefined) {
        if (this.validateResetToken(queryParams.token)) {
          this.resetToken = queryParams.token;
          this.showTokenInput = false;
        } else {
          this.resetToken = "";
          this.showTokenInput = true;
        }
        this.currentPage = ShowPage.EnterNewPassword;
      } else this.currentPage = ShowPage.EnterMail;
      if (queryParams.email) this.email = queryParams.email;
    });

    this.emailFormGroup = formBuilder.group({
      email: new FormControl("", {
        updateOn: "blur",
        validators: [Validators.email, Validators.required],
      }),
    });

    this.passwordFormGroup = formBuilder.group({
      password: new FormControl("", {
        updateOn: "change",
        validators: [Validators.required, Validators.minLength(8)],
      }),
    });
  }

  public validateEmail(mail: string): boolean {
    return mail && isValidEmail(mail);
  }

  public validateResetToken(token: string): boolean {
    return jwtDecode(token) !== null && jwtDecode(token) !== undefined;
  }

  public validatePassword(password: string): boolean {
    return password !== undefined;
  }

  public sendPasswordResetMail() {
    if (typeof this.email !== "string" || this.email === "")
      return this.notificationService.popup.error(
        "Ein Fehler ist aufgetreten",
        "Keine E-Mail angegeben"
      );
    if (!this.validateEmail(this.email))
      return this.notificationService.popup.error(
        "Ein Fehler ist aufgetreten",
        "Es wurde eine ungültige E-Mail eingegeben"
      );

    const redirectUrl: string =
      window.location.origin + window.location.pathname + "?token=";
    this.axiosApiClient
      .get(UsersApi)
      .sendResetPasswordMail({
        resetPasswordBody: { email: this.email, redirectUrl },
      })
      .then((r) => {
        if (r.status === 201) this.currentPage = ShowPage.CheckMailbox;
      });
  }

  public async changePassword() {
    if (typeof this.password !== "string")
      return this.notificationService.popup.error(
        "Fehler",
        "Es wurde kein Passwort eingegeben."
      );
    if (!this.validatePassword(this.password))
      return this.notificationService.popup.error(
        "Fehler",
        "Das Passwort entspricht nicht den Sicherheitsanforderungen"
      );

    this.axiosApiClient
      .get(UsersApi)
      .setPasswordWithResetToken({
        resetPasswordWithTokenBody: {
          password: this.password,
          token: this.resetToken,
        },
      })
      .then((r) => {
        if (r.status === 200) {
          this.tokenService.setLoginToken(r.data?.token);
          this.router.navigate(["/home"], { replaceUrl: true });
        }
      })
      .catch((e) => {
        if (e.statusCode === 404)
          return this.notificationService.popup.error(
            "Fehler",
            "Ungültiges Token"
          );
      });
  }
}
