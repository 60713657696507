<div>
  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item [routerLink]="['/fees-calculator']">Gebühren-Rechner</button>
    <!-- <button mat-menu-item [routerLink]="['/backoffice-user-details']">Backoffice User Details</button> -->
    <button mat-menu-item (click)="switchToYear(2021)">2021</button>
    <button mat-menu-item (click)="switchToYear(2020)">2020</button>
    <button mat-menu-item (click)="switchToYear(2019)">2019</button>
    <button mat-menu-item (click)="switchToYear(2018)">2018</button>
    <button mat-menu-item (click)="switchToYear(2017)">2017</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</div>
