import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filetype"
})
export class FileTypePipe implements PipeTransform {
  public transform(type: string) {
    switch (type) {
      case "application/pdf":
        return "PDF";
      case "application/zip":
        return "ZIP";
      case "image/jpeg":
        return "JPG";
      case "image/png":
        return "PNG";
      default:
        return "unbekannter Dateityp";
    }
  }
}
