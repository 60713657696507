<mat-toolbar class="top-toolbar purple">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <span class="mat-title" style="margin: 0px;">Belege scannen in {{currentTaxYear}}</span>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea" [ngClass]="uploadedFile !== undefined ? 'thumbsup' : 'smartphone'"></div>
            <div class="top left speech-bubble">
                <p *ngIf="!uploadedFile" class="mat-subtitle bea-talks">Ich würde es toll finden, wenn du Belege,
                    Rechnungen, Nachweise und all deine Dokumente direkt in der Erfassung an den Themenbereich anfügst,
                    wo sie hingehören. Wenn du jedoch mal nicht weißt, in welche Kategorie das Dokument kommt, dann
                    kannst du es einfach auch hier zentral ablegen.
                </p>
                <p *ngIf="uploadedFile" class="mat-subtitle bea-talks">Prima! Das hat geklappt.<br>Du hast erfolgreich
                    folgende Datei hochgeladen:</p>
            </div>
        </div>
    </div>
    <button mat-stroked-button *ngIf="!uploadedFile" color="primary" (click)="uploadFile()">Dokument hochladen</button>

    <div *ngIf="uploadedFile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div class="small card-boxes">
            <div class="card-box">
                <div class="content" fxLayout="column" fxLayoutAlign="space-between stretch" style="height:100%">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <h1 class="mat-subtitle">{{uploadedFile.name}}</h1>
                        <p class="mat-caption">{{uploadedFile.type | filetype}}</p>
                    </div>
                    <button mat-flat-button color="primary" style="margin-top: 20px;"
                        (click)="saveFileAtGeneralFiling()">Datei in Ablage verschieben</button>
                </div>
            </div>
        </div>
    </div>

</div>