<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3"
        src="/assets/icons/familie_kind_icon.svg" />
</div>
<mat-toolbar class="top-toolbar pink">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/kinder_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Kinder</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Hier kannst du Kinder-bezogene Daten wie Kindergeld, Unterhalt, Betreuungskosten, Schuldgeld etc. erfassen.</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">

            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper pink-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>

                <mat-step [completed]="taxreturn.data.subjectAreaChildren.numberOfChildren !== null">
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <ng-template matStepLabel>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span>Wie viele Kinder hast du?</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.numberOfChildren === 0 || taxreturn.data.subjectAreaChildren.numberOfChildren === null">Keine
                                    Kinder</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.numberOfChildren === 1">{{taxreturn.data.subjectAreaChildren.numberOfChildren}}
                                    Kind</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.numberOfChildren > 1">{{taxreturn.data.subjectAreaChildren.numberOfChildren}}
                                    Kinder</span>
                            </div>
                        </ng-template>
                        <mat-form-field>
                            <mat-label>Anzahl Kinder</mat-label>
                            <input matInput name="numberOfChildren" type="number" min="0" pattern="^[0-9]"
                                placeholder="eine Zahl zwischen 1 und 20?" optional
                                [(ngModel)]="taxreturn.data.subjectAreaChildren.numberOfChildren">
                        </mat-form-field>
                        <div fxLayour="column" *ngIf="taxreturn.data.subjectAreaChildren.numberOfChildren > 0">
                            <mat-form-field fxFill>
                                <mat-label>Vornamen deiner Kinder</mat-label>
                                <input matInput name="namesOfChildren" type="text" placeholder="mit Komma getrennt" optional
                                    [(ngModel)]="taxreturn.data.subjectAreaChildren.namesOfChildren">
                            </mat-form-field>
                            <mat-form-field fxFill>
                                <mat-label>Geburtstage deiner Kinder</mat-label>
                                <input matInput name="birthdatesOfChildren" type="text" placeholder="mit Komma getrennt" optional
                                    [(ngModel)]="taxreturn.data.subjectAreaChildren.birthdatesOfChildren">
                            </mat-form-field>
                            <mat-form-field fxFill>
                                <mat-label>Steuer-IDs deiner Kinder</mat-label>
                                <input matInput name="taxIdsOfChildren" type="text" placeholder="mit Komma getrennt" optional
                                    [(ngModel)]="taxreturn.data.subjectAreaChildren.taxIdsOfChildren">
                            </mat-form-field>
                            <mat-form-field fxFill>
                                <mat-label>Anschrift deiner Kinder (sofern abweichend)</mat-label>
                                <input matInput name="taxIdsOfChildren" type="text" placeholder="mit Komma getrennt" optional
                                    [(ngModel)]="taxreturn.data.subjectAreaChildren.addressOfChildren">
                            </mat-form-field>
                            <p class="mat-body">Bescheinigung über erhaltenes Elterngeld?</p>
                            <button mat-stroked-button color="accent" (click)="uploadFile('parentalAllowanceDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Bescheinigung hochladen
                            </button>
                            <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.parentalAllowanceDocs" subjectArea="subjectAreaChildren" option="parentalAllowanceDocs">
                            </uploaded-file-list>
                        </div>



                    </div>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaChildren.childBenefitDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Beziehst du Kindergeld?</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childBenefit">{{taxreturn.data.subjectAreaChildren.childBenefit | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childBenefitDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.childBenefitDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaChildren.childBenefitDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <mat-label>Wie hoch ist der monatlicher Beitrag</mat-label>
                            <input matInput name="childBenefit" type="number" placeholder="in Euro" optional
                                [(ngModel)]="taxreturn.data.subjectAreaChildren.childBenefit">
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('childBenefitDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.childBenefitDocs" subjectArea="subjectAreaChildren" option="childBenefitDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaChildren.childCareDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Kinderbetreuungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childCareCosts">{{taxreturn.data.subjectAreaChildren.childCareCosts | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childCareDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.childCareDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaChildren.childCareDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <mat-label>Monatlicher Beitrag</mat-label>
                            <input matInput name="childCareCosts"
                                [(ngModel)]="taxreturn.data.subjectAreaChildren.childCareCosts" type="number"
                                placeholder="in Euro" optional>
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('childCareDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.childCareDocs" subjectArea="subjectAreaChildren" option="childCareDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaChildren.childAlimonyDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Unterhaltsleistungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childAlimony">{{taxreturn.data.subjectAreaChildren.childAlimony | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaChildren.childAlimonyDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.childAlimonyDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaChildren.childAlimonyDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-label>Unterhaltsleistungen an volljährige Kinder, für die kein Kindergeld/Kinderfreibetrag mehr gewährt wird.</mat-label>
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <mat-label>Wie hoch ist der monatlicher Beitrag</mat-label>
                            <input matInput name="childAlimony"
                                [(ngModel)]="taxreturn.data.subjectAreaChildren.childAlimony" type="number"
                                placeholder="in Euro" optional>
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('childAlimonyDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.childAlimonyDocs" subjectArea="subjectAreaChildren" option="childAlimonyDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaChildren.foreignLodgingDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <span>Auswärtige Unterbringung</span>
                        <div fxLayout="column" fxLayoutAlign="center end">
                            <span
                                *ngIf="taxreturn.data.subjectAreaChildren.foreignLodgingDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.foreignLodgingDocs | doccount}}
                                {{(taxreturn.data.subjectAreaChildren.foreignLodgingDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <p class="mat-body">Nachweis über auswärtige Unterbringung der volljährigen Kinder. Sind deine
                            Kinder Studenten oder Auszubildende?</p>
                        <button mat-stroked-button color="accent" (click)="uploadFile('foreignLodgingDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.foreignLodgingDocs" subjectArea="subjectAreaChildren" option="foreignLodgingDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <span>Ausbildungsnachweise bei Kindern ab 18 Jahre</span>
                        <div fxLayout="column" fxLayoutAlign="center end">
                            <span
                                *ngIf="taxreturn.data.subjectAreaChildren.trainingCertificateDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.trainingCertificateDocs | doccount}}
                                {{(taxreturn.data.subjectAreaChildren.trainingCertificateDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <!-- <p class="mat-body">Hast du eine Immatrikulationsbescheinigung von deinen Kindern?</p> -->
                        <mat-label>z. B. Immatrikulationsbescheinigung, Ausbildungsvertrag</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('trainingCertificateDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.trainingCertificateDocs" subjectArea="subjectAreaChildren" option="trainingCertificateDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <span>Nachweise über etwaige Behinderungen</span>
                        <div fxLayout="column" fxLayoutAlign="center end">
                            <span
                                *ngIf="taxreturn.data.subjectAreaChildren.disabilityDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.disabilityDocs | doccount}}
                                {{(taxreturn.data.subjectAreaChildren.disabilityDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <button mat-stroked-button color="accent" (click)="uploadFile('disabilityDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweis hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaChildren?.disabilityDocs" subjectArea="subjectAreaChildren" option="disabilityDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>
                        <span>Krankenversicherungsbeiträge Ihrer Kinder</span>
                        <div fxLayout="column" fxLayoutAlign="center end">
                            <span
                                *ngIf="taxreturn.data.subjectAreaChildren.childHealthInsuranceContributionDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaChildren.childHealthInsuranceContributionDocs | doccount}}
                                {{(taxreturn.data.subjectAreaChildren.childHealthInsuranceContributionDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <button mat-stroked-button color="accent"
                            (click)="uploadFile('childHealthInsuranceContributionDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Beitrag hochladen
                        </button>
                    </div>
                    <uploaded-file-list
                        [docs]="taxreturn.data?.subjectAreaChildren?.childHealthInsuranceContributionDocs" subjectArea="subjectAreaChildren" option="childHealthInsuranceContributionDocs">
                    </uploaded-file-list>
                </mat-step>
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaChildren.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>
</div>