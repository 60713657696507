import { NgModule } from "@angular/core";
import { NavigationEnd, Router, RouterModule, Routes } from "@angular/router";
import { AuthorizedContainerComponent } from "./components/authorized-container/authorized-container.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserAuthenticatedGuard } from "./guards/user-authenticated.guard";
import { AblageComponent } from "./pages/ablage/ablage.component";
import { ArbeitnehmerComponent } from "./pages/arbeitnehmer/arbeitnehmer.component";
import { BackofficeUserPageComponent } from "./pages/backoffice-user-page/backoffice-user-page.component";
import { EinreichenComponent } from "./pages/einreichen/einreichen.component";
import { FeesCalculatorFormComponent } from "./pages/fees-calculator/fees-calculator-form.component";
import { HomeComponent } from "./pages/home/home.component";
import { ImmobilienComponent } from "./pages/immobilien/immobilien.component";
import { KinderComponent } from "./pages/kinder/kinder.component";
import { RentnerComponent } from "./pages/rentner/rentner.component";
import { ScanComponent } from "./pages/scan/scan.component";
import { SonderausgabenComponent } from "./pages/sonderausgaben/sonderausgaben.component";
import { SparenComponent } from "./pages/sparen/sparen.component";
import { StammdatenComponent } from "./pages/stammdaten/stammdaten.component";
import { LoginComponent } from "./pages/user/login/login.component";
import { PasswordResetComponent } from "./pages/user/password-reset/password-reset.component";
import { RegisterComponent } from "./pages/user/register/register.component";


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "user/login", component: LoginComponent },
  { path: "user/register", component: RegisterComponent },
  { path: "user/password-reset", component: PasswordResetComponent },
  {
    path: "",
    component: AuthorizedContainerComponent,
    canActivate: [UserAuthenticatedGuard],
    children: [
      { path: "home", component: HomeComponent },
      {
        path: "erfassen",
        children: [
          { path: "", component: DashboardComponent },
          { path: "immobilien", component: ImmobilienComponent },
          { path: "kinder", component: KinderComponent },
          { path: "rentner", component: RentnerComponent },
          { path: "sonderausgaben", component: SonderausgabenComponent },
          { path: "sparen", component: SparenComponent },
          { path: "arbeitnehmer", component: ArbeitnehmerComponent }
        ]
      },
      { path: "stammdaten", component: StammdatenComponent },
      { path: "ablage", component: AblageComponent },
      { path: "scan", component: ScanComponent },
      { path: "einreichen", component: EinreichenComponent },
      { path: "fees-calculator", component: FeesCalculatorFormComponent },
      { path: "backoffice-user-details", component: BackofficeUserPageComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
}
