<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3"
        src="/assets/icons/immobilienbesitzer_icon.svg" />
</div>
<mat-toolbar class="top-toolbar blue">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/immobilienbesitzer_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Immobilienbesitzer</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Mieteinnahmen, Nebenkosten, Instandhaltungen etc.</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper blue-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>

                <mat-step [completed]="taxreturn.data.subjectAreaProperties.rentalIncomeDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Vermietung und Verpachtung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.rentalIncomeDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.rentalIncomeDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.rentalIncomeDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise zu Einkünften aus Vermietung und Verpachtung (z. B. Mietvertrag)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('rentalIncomeDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweise hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.rentalIncomeDocs" subjectArea="subjectAreaProperties" option="rentalIncomeDocs">
                    </uploaded-file-list>
                </mat-step>

                <mat-step [completed]="taxreturn.data.subjectAreaProperties.acquisitionCostDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Anschaffungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.acquisitionCostDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.acquisitionCostDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.acquisitionCostDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Belege über Anschaffungskosten (Kaufvertrag, Maklergebühr, Auflassung, Grunderwerbsteuer, Notarkosten)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('acquisitionCostDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.acquisitionCostDocs" subjectArea="subjectAreaProperties" option="acquisitionCostDocs">
                    </uploaded-file-list>
                </mat-step>
                
                <mat-step [completed]="taxreturn.data.subjectAreaProperties.financingCostDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Finanzierungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.financingCostDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.financingCostDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.financingCostDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Finanzierungskosten (Darlehensverträge, Darlehenszinsen)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('financingCostDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.financingCostDocs" subjectArea="subjectAreaProperties" option="financingCostDocs">
                    </uploaded-file-list>
                </mat-step>

                <mat-step [completed]="taxreturn.data.subjectAreaProperties.operatingCostDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Betriebskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.operatingCostDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.operatingCostDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.operatingCostDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Belege über Betriebskosten und Kosten der Vermietung (Nebenkosten, Versicherungen, Reparaturen, Instandhaltungskosten)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('operatingCostDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.operatingCostDocs" subjectArea="subjectAreaProperties" option="operatingCostDocs">
                    </uploaded-file-list>
                </mat-step>

                <mat-step
                    [completed]="taxreturn.data.subjectAreaProperties.homeownersAssociationAnnualStatementDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Jahresabrechnung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.homeownersAssociationAnnualStatementDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.homeownersAssociationAnnualStatementDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.homeownersAssociationAnnualStatementDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Jahresabrechnung der Wohneigentümergemeinschaft (Eigentümer und Mieter)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent"
                                (click)="uploadFile('homeownersAssociationAnnualStatementDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Jahresabrechnung hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list
                        [docs]="taxreturn.data?.subjectAreaProperties?.homeownersAssociationAnnualStatementDocs" subjectArea="subjectAreaProperties" option="homeownersAssociationAnnualStatementDocs">
                    </uploaded-file-list>
                </mat-step>

                <mat-step [completed]="taxreturn.data.subjectAreaProperties.employmentRelationshipsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Beschäftigungsverhältnisse</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.employmentRelationshipsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.employmentRelationshipsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.employmentRelationshipsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Haushaltsnahe Beschäftigungsverhältnisse (Vertrag, Nachweis über Abführung der
                            Sozialversicherungsbeiträge)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent"
                                (click)="uploadFile('employmentRelationshipsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.employmentRelationshipsDocs" subjectArea="subjectAreaProperties" option="employmentRelationshipsDocs">
                    </uploaded-file-list>
                </mat-step>

                <mat-step [completed]="taxreturn.data.subjectAreaProperties.servicesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Dienstleistungen / Handwerkerleistungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.servicesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.servicesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.servicesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Haushaltsnahe Dienstleistungen und Handwerkerleistungen (Rechnungen,
                            Überweisungsnachweise, Verträge)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('servicesDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Dokumente hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.servicesDocs" subjectArea="subjectAreaProperties" option="servicesDocs">
                    </uploaded-file-list>
                </mat-step>
                
                <mat-step [completed]="taxreturn.data.subjectAreaProperties.movingCostDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Umzugskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaProperties.movingCostDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaProperties.movingCostDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaProperties.movingCostDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Umzugskosten</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('movingCostDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Dokumente hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaProperties?.movingCostDocs" subjectArea="subjectAreaProperties" option="movingCostDocs">
                    </uploaded-file-list>
                </mat-step>

                
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaProperties.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>
</div>