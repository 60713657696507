import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { Store } from "@smallstack/store";
import { Subscription } from "rxjs";
import { currentTaxReturn$$, currentTaxYear$, currentUser$$ } from "src/app/app.state";
import { TaxReturn } from "src/app/models/tax-return.entity";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy {
  public currentTaxYear: number;
  public currentTaxReturn: TaxReturn;
  public currentUser$$: Store = currentUser$$;

  public years: number[] = [2021, 2020, 2019, 2018, 2017];

  @ViewChild(MatMenuTrigger) public ddTrigger: MatMenuTrigger;

  private sub: Subscription = new Subscription();

  constructor(private router: Router) { }

  public ngOnInit(): void {
    this.sub.add(currentTaxYear$.subscribe((cty) => this.currentTaxYear = cty));
    this.sub.add(currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr));
  }

  public ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

  public switchTaxYear(ev: MouseEvent, year: number) {
    currentTaxYear$.next(year);
    this.ddTrigger.closeMenu();
    ev.stopPropagation();
    this.router.navigateByUrl("/erfassen");
  }
}
