import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@smallstack/i18n-components";
import { Store } from "@smallstack/store";
import { Subscription } from "rxjs";
import { currentTaxReturn$$, currentTaxYear$, currentUser$$ } from "src/app/app.state";
import { TaxReturn, TaxReturnStatus } from "src/app/models/tax-return.entity";
import { TaxReturnService } from "src/app/services/tax-return.service";
import config from "../../../../config.json";

@Component({
  templateUrl: "./einreichen.component.html",
  styleUrls: ["./einreichen.component.scss"]
})
export class EinreichenComponent implements OnInit, OnDestroy {

  public currentTaxYear: number;
  public currentTaxReturn: TaxReturn;
  public currentUser$$: Store = currentUser$$;
  public TaxReturnStatus = TaxReturnStatus;
  public years: number[] = [2021, 2020, 2019, 2018, 2017];
  public isSubmitting: boolean = false;

  public mailContent: any = {};

  private sub: Subscription = new Subscription();

  constructor(private taxReturnService: TaxReturnService, private notificationService: NotificationService, private router: Router, private httpClient: HttpClient) { }

  public ngOnInit(): void {
    this.sub.add(currentTaxYear$.subscribe((cty) => this.currentTaxYear = cty));
    this.sub.add(currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr));
  }

  public ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

  public editTaxYear(year: number) {
    currentTaxYear$.next(year);
    this.router.navigateByUrl("/erfassen");
  }

  public getStatus(status: TaxReturnStatus) {
    if (this.currentTaxReturn)
      return this.currentTaxReturn.status === status;
  }

  public showHint(whichCheckbox: string) {
    let hintText: string;
    if (whichCheckbox === "emailCommunication") {
      hintText = "Die Parteien sind damit einverstanden, dass zum Zwecke der Kommunikationserleichterung Dokumente und Daten auch per unverschlüsselter E-Mail versandt werden können. Diese Zustimmung gilt nicht für Daten Dritter. Den Parteien ist bekannt, dass mit der Übertragung per E-Mail Sicherheitsrisiken verbunden sind. Da E-Mails bei der Übertragung einem Zugriff durch Dritte unterliegen können, wird der Berater insofern von der beruflichen Verschwiegenheitspflicht entbunden. (Hinweis: Grundsätzlich ist eine verschlüsselte E-Mail-Kommunikation vorzuziehen. Daher ist in unserem E-Mail-Verkehr ist als Standardverschlüsselung grundsätzlich die sog. Transportverschlüsselung (SSL/TLS-Verschlüsselung) vorgesehen. Eine sog. Ende-zu-Ende-Verschlüsselung ist nicht zwingend erforderlich.).";
    } else if (whichCheckbox === "invoiceViaMail") {
      hintText = "Der Auftraggeber erklärt sein Einverständnis, dass die Steuerberatungsgesellschaft Honorarrechnungen nur noch als PDF-Anhang per E-Mail an ihn verschickt. Auf das Schriftform- und Unterschriftserfordernis des § 9 Abs. 1 StBVV wird verzichtet. Auf Wunsch übermittelt die Steuerberatungsgesellschaft eine unterzeichnete Honorarrechnung in Schriftform.";
    }
    this.notificationService.popup.info("Hinweis", hintText);
  }

  public submitCurrentTaxReturn() {
    this.notificationService.popup.confirmation("Erklärung einreichen", "Wollen Sie sicher Ihre erfassten Daten einreichen? Hiermit beauftragen Sie mich verbindlich, Ihre Steuererklärung durchzuführen und an das Finanzamt zu übermitteln.", [{
      classes: "mat-flat-button confirm-cancel", label: "Abbrechen", value: false
    }, {
      classes: "mat-flat-button mat-warn confirm-submit", label: "Ja, verblich einreichen", value: true
    }], async (answer: boolean) => {
      if (answer === true) {
        if (this.currentTaxReturn)
          this.currentTaxReturn.status = TaxReturnStatus.submitted;
        this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(this.currentTaxReturn), {
          successMsg: "Steuererklärung erfolgreich eingereicht",
          errorMsg: "Übermittlung fehlgeschlagen"
        }).then(() => {
          // hier wird noch 2 Mails and den Benutzer und an commtax versendet
          this.sendMails();
        });
      }
    });
  }

  public async sendMails() {
    this.mailContent.userMail = currentUser$$.value.emails[0].address;
    try {
      this.isSubmitting = true;
      await this.httpClient.post(config.functions.submitMailUrl, this.mailContent).toPromise();
      this.notificationService.notification.info("E-Mail Benachrichtigungen versendet!", "ok");
    } catch (e) {
      this.notificationService.notification.error("Es ist ein Fehler aufgetreten: " + e.message, "ok");
    } finally {
      this.isSubmitting = false;
    }
  }
}
