<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3" src="/assets/icons/rentner_icon.svg" />
</div>
<mat-toolbar class="top-toolbar yellow">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/rentner_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Rentner</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Rentenversicherung, Altersvorsorgeverträgen, Selbständigkeit</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper yellow-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>

                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.pensionInsuranceDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Rentenversicherung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.pensionInsuranceDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.pensionInsuranceDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.pensionInsurance | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Bescheinigung über Leistungen der gesetzlichen und privaten Rentenversicherung (z. B.
                            Altersrente, Erwerbs-, Berufsunfähigkeits-, Witwen-, Waisenrente)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('pensionInsuranceDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Dokumente hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.pensionInsuranceDocs"
                        subjectArea="subjectAreaPensioner" option="pensionInsuranceDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaPensioner.retirementProvisionDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Altersvorsorge</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.retirementProvisionDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.retirementProvisionDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.retirementProvision | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Leistungen aus Altersvorsorgeverträgen, Pensionsfond, Pensionskasse und
                            Direktversicherung</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('retirementProvisionDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.retirementProvisionDocs"
                        subjectArea="subjectAreaPensioner" option="retirementProvisionDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.foreignIncomeDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Ausländische Einkünfte</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.foreignIncomeDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.foreignIncomeDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.foreignIncome | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Einkünfte aus dem Ausland</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('foreignIncomeDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Nachweise hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.foreignIncomeDocs"
                        subjectArea="subjectAreaPensioner" option="foreignIncomeDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.alimonyDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Unterhaltsleistungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.alimonyDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.alimonyDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.alimony | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Erhaltene Unterhaltsleistungen vom geschiedenen Ehegatten</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('alimonyDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.alimonyDocs"
                        subjectArea="subjectAreaPensioner" option="alimonyDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.salesTransactionsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Veräußerungsgeschäfte</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.salesTransactionsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.salesTransactionsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.salesTransactions | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Einnahmen aus privaten Veräußerungsgeschäften (Spekulationsgeschäfte)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('salesTransactionsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.salesTransactionsDocs"
                        subjectArea="subjectAreaPensioner" option="salesTransactionsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaPensioner.mediationAndRentalDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Vermittlungen/Vermietung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.mediationAndRentalDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.mediationAndRentalDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.mediationAndRental | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Sonstige Einnahmen aus gelegentlichen Vermittlungen und aus der Vermietung von
                            beweglichen Gegenständen</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('mediationAndRentalDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.mediationAndRentalDocs"
                        subjectArea="subjectAreaPensioner" option="mediationAndRentalDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaPensioner.compensationAndGrantsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Entschädigungen/Zuschüsse</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.compensationAndGrantsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.compensationAndGrantsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.compensationAndGrants | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Entschädigungen, Amtszulagen, Zuschüsse, Übergangsgelder, Überbrückungsgelder
                        </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('compensationAndGrantsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.compensationAndGrantsDocs"
                        subjectArea="subjectAreaPensioner" option="compensationAndGrantsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.pensionPaymentsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Versorgungsbezüge</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.pensionPaymentsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.pensionPaymentsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.pensionPayments | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Versorgungsbezüge aufgrund des Abgeordnetengesetzes bzw. Europaabgeordnetengesetzes
                        </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('pensionPaymentsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.pensionPaymentsDocs"
                        subjectArea="subjectAreaPensioner" option="pensionPaymentsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaPensioner.educatorIncomeDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Ausbilder/Erzieher</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.educatorIncomeDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.educatorIncomeDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.educatorIncome | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Einnahmen als nebenberuflicher Übungsleiter, Ausbilder, Erzieher, Betreuer, etc.
                        </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('educatorIncomeDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.educatorIncomeDocs"
                        subjectArea="subjectAreaPensioner" option="educatorIncomeDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaPensioner.businessAndParticipationIncomeDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Gewerbebetrieb/Beteiligungen</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.businessAndParticipationIncomeDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.businessAndParticipationIncomeDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.businessAndParticipationIncome | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Einkünfte aus Gewerbebetrieb (Beteiligungen, Gewinnermittlung)
                        </mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent"
                                (click)="uploadFile('businessAndParticipationIncomeDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list
                        [docs]="taxreturn.data?.subjectAreaPensioner?.businessAndParticipationIncomeDocs"
                        subjectArea="subjectAreaPensioner" option="businessAndParticipationIncomeDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaPensioner.independentWorkIncomeDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Selbständige Arbeit</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaPensioner.independentWorkIncomeDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaPensioner.independentWorkIncomeDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaPensioner.independentWorkIncome | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Einkünfte aus selbständiger Arbeit (Gewinnermittlung)</mat-label>
                        <div fxLayout="column" fxLayoutAlign="center stretch">
                            <button mat-stroked-button color="accent" (click)="uploadFile('independentWorkIncomeDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaPensioner?.independentWorkIncomeDocs"
                        subjectArea="subjectAreaPensioner" option="independentWorkIncomeDocs">
                    </uploaded-file-list>
                </mat-step>
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaPensioner.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>

</div>