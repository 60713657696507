import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AxiosApiClient, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { TokenService } from "@smallstack/common-components";
import { Logger } from "@smallstack/core-common";
import { currentUser$$ } from "../app.state";

@Injectable()
export class UserAuthenticatedGuard implements CanActivate {

  constructor(private router: Router, private tokenService: TokenService, private axiosApiClient: AxiosApiClient) {
  }

  public async canActivate(): Promise<boolean> {
    // check if there's a token in local storage
    if (!this.tokenService.isTokenValid()) {
      this.router.navigate(["/user/login"]);
      Logger.debug("UserAuthenticatedGuard", "no token found in local storage");
      return false;
    }
    // try to log user in
    let user: UserDto = currentUser$$.value;
    if (!user) {
      Logger.debug("UserAuthenticatedGuard", "no currentUser$$.value, asking axiosApiClient");
      try {
        currentUser$$.setLoading();
        user = (await this.axiosApiClient.get(UsersApi).getMyUser()).data;
        currentUser$$.setValue(user);
        Logger.debug("UserAuthenticatedGuard", "set new currentUser$$.value:", user);
      } catch (e) { Logger.debug("UserAuthenticatedGuard", e); }
    }
    if (user)
      return true;

    this.router.navigate(["/user/login"]);
    return false;
  }
}
