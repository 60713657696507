import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "@smallstack/common-components";
import { currentTaxYear$, currentUser$$ } from "src/app/app.state";

@Component({
  selector: "settings-button",
  templateUrl: "./settings-button.component.html"
})
export class SettingsButtonComponent {

  constructor(private tokenService: TokenService, private router: Router) { }

  public logout() {
    this.tokenService.invalidateTokenLocally();
    currentUser$$.reset();
    this.router.navigate(["/user/login"]);
  }

  public switchToYear(year: number) {
    currentTaxYear$.next(year);
  }
}
