import { UserDto } from "@smallstack/axios-api-client";
import { Store } from "@smallstack/store";
import { BehaviorSubject } from "rxjs";
import { TaxReturn } from "./models/tax-return.entity";



export const currentUser$$: Store<UserDto> = new Store<UserDto>(undefined);
export const currentTaxReturn$$: Store<TaxReturn> = new Store<TaxReturn>(undefined);
export const myTaxReturns$$: Store<TaxReturn[]> = new Store<TaxReturn[]>(undefined);
export const currentTaxYear$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
