import { Pipe, PipeTransform } from "@angular/core";
import { TaxReturnDocument } from "../models/tax-return.entity";

@Pipe({
  name: "doccount"
})
export class DocCountPipe implements PipeTransform {
  public transform(docs: TaxReturnDocument[], param?: string) {
    switch (param) {
      case "notempty":
        return docs?.length > 0;
      case "exactone":
        return docs?.length === 1;
      default:
        return docs?.length;
    }
  }
}
