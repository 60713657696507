<mat-toolbar class="top-toolbar purple">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <span class="mat-title" style="margin: 0px;" fxHide.xs>Steuererklärung {{currentTaxYear}}</span>
        <span class="mat-title" style="margin: 0px;" fxHide.gt-xs>{{currentTaxYear}}</span>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <store-container [store]="currentUser$$">
                <ng-container *ngIf="currentUser$$.value$ | async as currentUser">
                    <span class="mat-body-1" style="font-weight: bold;">{{currentUser.displayName}}</span>
                </ng-container>
            </store-container>
            <settings-button></settings-button>
        </div>
    </div>
</mat-toolbar>


<div class="container" *ngIf="currentTaxReturn">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Mein Datenerfassungs-Board soll dir helfen, einen Überblick über alle für dich relevanten Themenbereiche zu behalten. Bereiche, die dich nicht betreffen, kannst du einfach ausblenden.</p>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="card-boxes">
        <div class="card-box centericon" [routerLink]="['/stammdaten']" style="cursor: pointer;">
            <div class="content">
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center">
                    <img style="width: 60px; height: 60px;" src="assets/icons/stammdaten.svg" />
                    <h1 class="mat-h3">Stammdaten</h1>
                    <p class="mat-caption description">Name, Kontakt und weitere personenbezogene Daten</p>
                </div>
            </div>
        </div>
    </div>
    <div class="card-boxes">
        <div class="card-box centericon pink" [class.disabled]="!currentTaxReturn.data.subjectAreaChildren.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaChildren.isActive" (change)="toggleChildren($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/kinder']" [class.disabled]="!currentTaxReturn.data.subjectAreaChildren.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/kinder_icon.svg" />
                    <h1 class="mat-h3">Kinder</h1>
                    <p class="mat-caption description">Betreuungskosten, Schuldgeld, Kindergeld etc.</p>
                </div>
            </div>
        </div>
        <div class="card-box centericon red" [class.disabled]="!currentTaxReturn.data.subjectAreaSpecialCosts.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaSpecialCosts.isActive" (change)="toggleSpecialCosts($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/sonderausgaben']" [class.disabled]="!currentTaxReturn.data.subjectAreaSpecialCosts.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/sonderausgaben_icon.svg" />
                    <h1 class="mat-h3">Sonderausgaben / außergewöhnliche Belastungen</h1>
                    <p class="mat-caption description">Versicherungsbeiträge, Krankheitskosten, Behinderungen etc.</p>
                </div>
            </div>
        </div>
        <div class="card-box centericon green" [class.disabled]="!currentTaxReturn.data.subjectAreaWorkers.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaWorkers.isActive" (change)="toggleWorkers($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/arbeitnehmer']" [class.disabled]="!currentTaxReturn.data.subjectAreaWorkers.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/arbeitnehmer_icon.svg" />

                    <h1 class="mat-h3">Arbeitnehmer/ Werbungskosten</h1>
                    <p class="mat-caption description">Berufsbedingte Aufwendungen und Werbungskosten</p>
                </div>
            </div>
        </div>
        <div class="card-box centericon blue" [class.disabled]="!currentTaxReturn.data.subjectAreaProperties.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaProperties.isActive" (change)="toggleProperties($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/immobilien']" [class.disabled]="!currentTaxReturn.data.subjectAreaProperties.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/immobilienbesitzer_icon.svg" />
                    <h1 class="mat-h3">Immobilienbesiter und Mieter</h1>
                    <p class="mat-caption description">Mieteinnahmen, Nebenkosten, Instandhaltungen etc.</p>
                </div>
            </div>
        </div>
        <div class="card-box centericon orange" [class.disabled]="!currentTaxReturn.data.subjectAreaInvestor.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaInvestor.isActive" (change)="toggleInvestors($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/sparen']" [class.disabled]="!currentTaxReturn.data.subjectAreaInvestor.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/sparer_icon.svg" />
                    <h1 class="mat-h3">Sparer/ Anleger</h1>
                    <p class="mat-caption description">Kapitalerträge</p>
                </div>
            </div>
        </div>
        <div class="card-box centericon yellow" [class.disabled]="!currentTaxReturn.data.subjectAreaPensioner.isActive">
            <div class="content">
                <mat-slide-toggle [checked]="currentTaxReturn.data.subjectAreaPensioner.isActive" (change)="togglePensioner($event)"
                    class="dashboard-card-toggle" fxFlexAlign="end"></mat-slide-toggle>
                <div class="dashboard-card-clickable" fxLayout="column" fxLayoutAlign="center center"
                    [routerLink]="['/erfassen/rentner']" [class.disabled]="!currentTaxReturn.data.subjectAreaPensioner.isActive">
                    <img class="dashboard-card-icon" src="assets/icons/rentner_icon.svg" />
                    <h1 class="mat-h3">Renter/ Sonstige Einkünfte</h1>
                    <p class="mat-caption description">Rentenversicherung, Altersvorsorgeverträgen, Selbständigkeit</p>
                </div>
            </div>
        </div>
    </div>
</div>