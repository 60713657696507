<div class="content">
  <div class="login-container" fxLayout="column" fxLayoutAlign="center stretch" fxFlexAlign="center">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" class="login-box">
      <div fxLayout="column" fxLayoutAlign="space-evenly stretch"  fxLayoutAlign.lt-md="start stretch" fxFlex="55" fxFlex.lt-md="65" fxFlex.xs="75">
        <div fxLayout="column" fxFlexAlign="center" style="padding: 20px 20px 0px 20px;">
          <img src="assets/icons/logo.svg" alt="logo" style="width: 120px;">
          <h1 class="mat-subtitle" style="margin: 0px;">ANMELDUNG</h1>
          <p class="mat-body-1" style="margin-top: 0px;">Melde dich hier an, um deine Steuererklärungen einzureichen. Wenn du noch kein Konto hast, kannst du unten eins erstellen!</p>
        </div>
        <form [formGroup]="formGroup" fxLayout="column" fxLayoutGap="10px" style="padding: 0px 20px 20px;">
          <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <input matInput placeholder="bea@taxgirl.de" type="email" required class="form-control"
              formControlName="email">
            <mat-error *ngIf="formGroup.get('email').hasError('required')">Bitte geben Sie eine E-Mail Adresse ein!
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('email')">Das Format der eingegebenen E-Mail Adresse ist
              inkorrekt!</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Passwort</mat-label>
            <input matInput type="password" required class="form-control" formControlName="password">
            <mat-error *ngIf="formGroup.get('password').hasError('required')">Bitte geben Sie Ihr Passwort ein!
            </mat-error>
          </mat-form-field>
          <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin: 20px 0px;">
            <button mat-raised-button [disabled]="formGroup.invalid" color="primary" (click)="login()"
              fxFlex="45">Login</button>
            <a [routerLink]="['../register']" mat-stroked-button color="accent" fxFlex="45">Konto erstellen</a>
          </div>
        </form>
        <div fxLayout="row" fxLayoutAlign="end center" style="padding-right: 20px;">
          <a class="text-link" [routerLink]="['../password-reset']">Passwort vergessen?</a>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="45" fxFlex.lt-md="35" fxFlex.xs="25" class="lounge">
        <img src="assets/images/bea-presenting.png" alt="Lounge" class="bea-welcome-image">
      </div>
    </div>
  </div>
</div>