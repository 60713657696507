<div class="shapes">
    <!-- <img class="rellax fly-shape" data-rellax-speed="-2" src="/assets/icons/alleinerziehende.svg" /> -->
    <img class="rellax fly-shape fly-shape_right_bottom" data-rellax-speed="3"
        src="/assets/icons/arbeitnehmer_icon.svg" />
</div>
<mat-toolbar class="top-toolbar green">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="top-toolbar-row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <back-button></back-button>
            <img class="appbar-icon" src="assets/icons/arbeitnehmer_icon.svg" />
            <span class="mat-title" style="margin: 0px;">Arbeitnehmer</span>
        </div>
        <settings-button></settings-button>
    </div>
</mat-toolbar>

<div class="container">
    <div class="page-header" fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="width: 100%;">
            <div class="bea presenting"></div>
            <div class="top left speech-bubble">
                <p class="mat-subtitle bea-talks">Hier werden alle berufsbedingten Aufwendungen und Werbungskosten hinterlegt.</p>
            </div>
        </div>
    </div>
    <current-tax-return-container>
        <ng-template let-taxreturn="taxreturn">
            <mat-vertical-stepper linear="false" color="accent" #stepper class="stepper green-icon">
                <!-- Icon overrides -->
                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number">
                    <mat-icon>edit</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>
                
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.electronicEmploymentTaxCertificate | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Elektronische Lohnsteuerbescheinigung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.electronicEmploymentTaxCertificate | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.electronicEmploymentTaxCertificate | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.electronicEmploymentTaxCertificate | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Hier kannst du deine elektronische Lohnsteuerbescheinigung hochladen. Diese Jahresabrechnung, die du von deinem Arbeitgeber bekommen hast.</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('electronicEmploymentTaxCertificate')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Lohnsteuerbescheinigung hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.electronicEmploymentTaxCertificate" subjectArea="subjectAreaWorkers" option="electronicEmploymentTaxCertificate">
                    </uploaded-file-list>
                </mat-step>
                
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.notificationOfAbsenteePayments | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Leistungsbescheide</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.notificationOfAbsenteePayments | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.notificationOfAbsenteePayments | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.notificationOfAbsenteePayments | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Leistungsbescheide über geleistete Zahlungen bei Fehlzeiten (z. B. Bezug von Arbeitslosengeld, Krankengeld, Mutterschaftsgeld, Elterngeld, Kurzarbeitergeld) </mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('notificationOfAbsenteePayments')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Leistungsbescheide hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.notificationOfAbsenteePayments" subjectArea="subjectAreaWorkers" option="notificationOfAbsenteePayments">
                    </uploaded-file-list>
                </mat-step>
                
                <mat-step
                    [completed]="taxreturn.data.subjectAreaWorkers.distanceToOffice > 0 && taxreturn.data.subjectAreaWorkers.numberOfDaysDrivenToWork > 0 && taxreturn.data.subjectAreaWorkers.meansOfTransport !== ''">
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <ng-template matStepLabel>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <span>Fahrtenkosten</span>
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <span
                                        *ngIf="taxreturn.data.subjectAreaWorkers.distanceToOffice"><strong>{{taxreturn.data.subjectAreaWorkers.distanceToOffice}}</strong>
                                        km</span>
                                    <span *ngIf="taxreturn.data.subjectAreaWorkers.numberOfDaysDrivenToWork">&nbsp;an
                                        <strong>{{taxreturn.data.subjectAreaWorkers.numberOfDaysDrivenToWork}}</strong>
                                        Tagen im Jahr</span>
                                    <span *ngIf="taxreturn.data.subjectAreaWorkers.meansOfTransport">&nbsp;mit
                                        <strong>{{taxreturn.data.subjectAreaWorkers.meansOfTransport}}</strong></span>
                                </div>
                            </div>
                        </ng-template>
                        <mat-label>Fahrten zwischen Wohnung und Arbeitsstätte</mat-label>
                        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center"
                            fxLayoutAlign.xs="center stretch" fxLayoutGap="16px">
                            <mat-form-field>
                                <mat-label>Entfernung zur Arbeit</mat-label>
                                <input matInput name="distanceToOffice" type="number"
                                    placeholder="genaue Kilometer-Angabe" optional
                                    [(ngModel)]="taxreturn.data.subjectAreaWorkers.distanceToOffice">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Anzahl der Tage</mat-label>
                                <input matInput name="numberOfDaysDrivenToWork" type="number" placeholder="pro Jahr"
                                    optional [(ngModel)]="taxreturn.data.subjectAreaWorkers.numberOfDaysDrivenToWork">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Verkehrsmittel</mat-label>
                                <input matInput name="meansOfTransport" placeholder="Auto, Bus, Bahn etc." optional
                                    [(ngModel)]="taxreturn.data.subjectAreaWorkers.meansOfTransport">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.workEquipmentCostDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Kosten für Arbeitsmittel</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.workEquipmentCostDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.workEquipmentCostDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.workEquipmentCostDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Berufsbekleidung, Werkzeuge und Fachliteratur</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('workEquipmentCostDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.workEquipmentCostDocs" subjectArea="subjectAreaWorkers" option="workEquipmentCostDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.trainingCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Fortbildungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.trainingCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.trainingCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.trainingCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Gebühren, Schreibmaterial, Fahrt- und Übernachtungskosten</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('trainingCostsDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.trainingCostsDocs" subjectArea="subjectAreaWorkers" option="trainingCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.applicationCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Bewerbungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.applicationCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.applicationCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.applicationCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Bewerbungsunterlagen, Telefon- und Internetkosten, Kosten für
                            Vorstellungsgespräche
                            (Fahrt-
                            und Übernachtungskosten)</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('applicationCostsDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.applicationCostsDocs" subjectArea="subjectAreaWorkers" option="applicationCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.missionExpensesDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Reisekosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.missionExpensesDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.missionExpensesDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.missionExpensesDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Nachweise über Reisekosten für dienstliche Zwecke: Fahrtkosten, Dauer,
                            Übernachtungskosten,
                            Nebenkosten (nicht vergesse: Erstattungen des Arbeitgebers abziehen)</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('missionExpensesDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.missionExpensesDocs" subjectArea="subjectAreaWorkers" option="missionExpensesDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.unionContributionDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Beruf/Gewerkschaft</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.unionContributionDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.unionContributionDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.unionContributionDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Beiträge zu Berufsverbänden und Gewerkschaften</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('unionContributionDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.unionContributionDocs" subjectArea="subjectAreaWorkers" option="unionContributionDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.taxAdviceCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Steuerberatungskosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.taxAdviceCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.taxAdviceCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.taxAdviceCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Ja, auch die Abrechnung zur Erstellung dieser Einkommenssteuer, kann bei der
                            nächsten Erklärung geltend gemacht werden.</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('taxAdviceCostsDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Rechnung hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.taxAdviceCostsDocs" subjectArea="subjectAreaWorkers" option="taxAdviceCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step [completed]="taxreturn.data.subjectAreaWorkers.accidentCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Unfallkosten</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.accidentCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.accidentCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.accidentCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Kosten für Unfälle auf dem Weg zur Arbeits oder auf Dienstreisen,
                            Erstattungen der Versicherung.</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('accidentCostsDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Kostennachweis hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.accidentCostsDocs" subjectArea="subjectAreaWorkers" option="accidentCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaWorkers.privateAccidentCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Private Unfall- und Rechtsschutz</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.privateAccidentCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.privateAccidentCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.privateAccidentCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                        <mat-label>Beruflicher Beitragsanteil für private Unfall- und
                            Rechtsschutzversicherungen.</mat-label>
                        <button mat-stroked-button color="accent" (click)="uploadFile('privateAccidentCostsDocs')">
                            <mat-icon>cloud_upload</mat-icon>&nbsp;Kostennachweis hochladen
                        </button>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.privateAccidentCostsDocs" subjectArea="subjectAreaWorkers" option="privateAccidentCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaWorkers.workSpaceCosts > 0 && taxreturn.data.subjectAreaWorkers.workSpaceCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Kosten für ein Arbeitszimmer</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.workSpaceCosts">{{taxreturn.data.subjectAreaWorkers.workSpaceCosts | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.workSpaceCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.workSpaceCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.workSpaceCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <mat-label>Gesamtkosten</mat-label>
                            <input matInput name="workSpaceCosts"
                                [(ngModel)]="taxreturn.data.subjectAreaWorkers.workSpaceCosts" type="number"
                                placeholder="in Euro" optional>
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('workSpaceCostsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.workSpaceCostsDocs" subjectArea="subjectAreaWorkers" option="workSpaceCostsDocs">
                    </uploaded-file-list>
                </mat-step>
                <mat-step
                    [completed]="taxreturn.data.subjectAreaWorkers.doubleHousingCosts > 0 && taxreturn.data.subjectAreaWorkers.workSpaceCostsDocs | doccount:'notempty'">
                    <ng-template matStepLabel>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <span>Kosten für doppelte Haushaltsführung</span>
                            <div fxLayout="column" fxLayoutAlign="center end">
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.doubleHousingCosts">{{taxreturn.data.subjectAreaWorkers.doubleHousingCosts | currency:'EUR':'symbol':'2.2-2':'de'}}</span>
                                <span
                                    *ngIf="taxreturn.data.subjectAreaWorkers.doubleHousingCostsDocs | doccount:'notempty'">{{taxreturn.data.subjectAreaWorkers.doubleHousingCostsDocs | doccount}}
                                    {{(taxreturn.data.subjectAreaWorkers.doubleHousingCostsDocs | doccount:'exactone') ? 'Dokument' : 'Dokumente'}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="margin-bottom: 40px;">
                        <mat-form-field fxFlex="60" fxFlex.xs="100">
                            <mat-label>Gesamtkosten pro Monat</mat-label>
                            <input matInput name="doubleHousingCosts"
                                [(ngModel)]="taxreturn.data.subjectAreaWorkers.doubleHousingCosts" type="number"
                                placeholder="monatliche Miete etc. in Euro" optional>
                        </mat-form-field>
                        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="35" fxFlex.xs="100">
                            <button mat-stroked-button color="accent" (click)="uploadFile('doubleHousingCostsDocs')">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;Belege hochladen
                            </button>
                        </div>
                    </div>
                    <uploaded-file-list [docs]="taxreturn.data?.subjectAreaWorkers?.doubleHousingCostsDocs" subjectArea="subjectAreaWorkers" option="doubleHousingCostsDocs">
                    </uploaded-file-list>
                </mat-step>
            </mat-vertical-stepper>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Bemerkungen</mat-label>
                    <textarea matInput rows="5" name="hints" [(ngModel)]="taxreturn.data.subjectAreaWorkers.hints"
                        placeholder="Bemerkungen, Feedback, Hinweise"></textarea>
                    <mat-hint>Solltest du noch Fragen haben oder einen wichtigen Hinweis für uns, kannst du das hier
                        einfach
                        reinschreiben!</mat-hint>
                </mat-form-field>
            </div>
        </ng-template>
    </current-tax-return-container>
</div>