import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Store } from "@smallstack/store";
import { Subscription } from "rxjs";
import { currentTaxReturn$$, currentTaxYear$, currentUser$$ } from "../app.state";
import { TaxReturn } from "../models/tax-return.entity";
import { TaxReturnService } from "../services/tax-return.service";


@Component({
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {

  public currentTaxYear: number;
  public currentTaxReturn: TaxReturn;
  public currentTaxReturn$$: Store = currentTaxReturn$$;
  public currentUser$$: Store = currentUser$$;

  private sub: Subscription = new Subscription();

  constructor(private taxReturnService: TaxReturnService) { }

  public ngOnInit() {
    this.sub.add(currentTaxYear$.subscribe((cty) => this.currentTaxYear = cty));
    this.sub.add(currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr));
  }

  public ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

  public toggleChildren(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaChildren.isActive = event.checked;
    this.saveTaxReturn();
  }
  public toggleSpecialCosts(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaSpecialCosts.isActive = event.checked;
    this.saveTaxReturn();
  }
  public toggleWorkers(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaWorkers.isActive = event.checked;
    this.saveTaxReturn();
  }
  public toggleProperties(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaProperties.isActive = event.checked;
    this.saveTaxReturn();
  }
  public toggleInvestors(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaInvestor.isActive = event.checked;
    this.saveTaxReturn();
  }
  public togglePensioner(event: MatSlideToggleChange) {
    this.currentTaxReturn.data.subjectAreaPensioner.isActive = event.checked;
    this.saveTaxReturn();
  }

  public saveTaxReturn() {
    this.taxReturnService.updateTaxReturn(this.currentTaxReturn);
  }
}
