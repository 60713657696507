<router-outlet></router-outlet>
<mat-toolbar class="bottom-tabbar">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="bottom-tabbar-row">
      <button mat-button class="bottom-tabbar-button" [routerLink]="['/home']"
          routerLinkActive="tabbar-tab-active">
          <img class="toolbar-icon" src="assets/icons/home_icon.svg" />
          <p class="mat-caption">Home</p>
      </button>
      <button mat-button class="bottom-tabbar-button" [routerLink]="['/erfassen']"
          routerLinkActive="tabbar-tab-active">
          <img class="toolbar-icon" src="assets/icons/daten_erfassen.svg" />
          <p class="mat-caption">Steuererfassung</p>
      </button>
      <button mat-button class="bottom-tabbar-button" [routerLink]="['/scan']" routerLinkActive="tabbar-tab-active">
          <img class="toolbar-icon" src="assets/icons/scan_icon.svg" />
          <p class="mat-caption">Scan</p>
      </button>
      <button mat-button class="bottom-tabbar-button" [routerLink]="['/ablage']" routerLinkActive="tabbar-tab-active">
          <img class="toolbar-icon" src="assets/icons/ablage_icon.svg" />
          <p class="mat-caption">Ablage</p>
      </button>
      <button mat-button class="bottom-tabbar-button" [routerLink]="['/einreichen']"
          routerLinkActive="tabbar-tab-active">
          <img class="toolbar-icon" src="assets/icons/einreichen_icon.svg" />
          <p class="mat-caption">Einreichen</p>
      </button>
  </div>
</mat-toolbar>
