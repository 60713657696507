<ng-container *ngIf="docs?.length > 0">
    <p class="mat-body">Hochgeladene Dokumente:</p>
    <div class="small card-boxes">
        <div class="card-box"
            *ngFor="let doc of docs">
            <div class="content" fxLayout="column" fxLayoutAlign="space-between stretch" style="height:100%">
                <button mat-icon-button class="remove-file-button" (click)="removeFile(doc)"><mat-icon>close</mat-icon></button> 
                <div fxLayout="column" fxLayoutAlign="start stretch" style="overflow: hidden;">
                    <h1 class="mat-subtitle">{{doc.name}}</h1>
                    <mat-icon *ngIf="doc.type === 'application/pdf'" class="doc-icon">picture_as_pdf</mat-icon>
                    <img *ngIf="doc.type === 'image/jpeg' || doc.type === 'image/png'" [src]="doc.url" class="thumbnail" />
                    <p class="mat-caption" style="text-align: center;">{{doc.type | filetype}}<br>{{doc.fileSize | filesize}}</p>
                </div>
                <a href="{{doc.url}}" target="blank" mat-button>Öffnen</a>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="docs?.length === 0 && showInFiling">Sie haben hier noch keine Dokumente abgelegt...</ng-container>