import { Component, Input } from "@angular/core";
import { NotificationService } from "@smallstack/i18n-components";
import { currentTaxReturn$$ } from "src/app/app.state";
import { TaxReturn, TaxReturnDocument } from "src/app/models/tax-return.entity";
import { TaxReturnService } from "src/app/services/tax-return.service";

@Component({
  selector: "uploaded-file-list",
  templateUrl: "./uploaded-file-list.component.html",
  styleUrls: ["./uploaded-file-list.component.scss"]
})

export class UploadedFileListComponent {
  public currentTaxReturn: TaxReturn;

  @Input()
  public docs: TaxReturnDocument[];

  @Input()
  public showInFiling: boolean = false;

  @Input()
  public subjectArea: string;

  @Input()
  public option: string;

  constructor(private taxReturnService: TaxReturnService, private notificationService: NotificationService) {
    currentTaxReturn$$.value$.subscribe((ctr) => this.currentTaxReturn = ctr);
  }

  public removeFile(doc: TaxReturnDocument) {
    if (this.currentTaxReturn && doc) {
      let docIndex;
      if (this.showInFiling === true) {
        docIndex = this.currentTaxReturn.data.general.filing.findIndex((file) => file.id === doc.id);
        if (docIndex !== -1) {
          this.currentTaxReturn.data.general.filing.splice(docIndex, 1);
          this.docs.splice(docIndex, 1);
        }
      } else if (this.subjectArea && this.option) {
        docIndex = this.currentTaxReturn.data[this.subjectArea][this.option].findIndex((file) => file.id === doc.id);
        if (docIndex !== -1) {
          this.currentTaxReturn.data[this.subjectArea][this.option].splice(docIndex, 1);
        }
      }

      if (docIndex !== -1) {
        this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(this.currentTaxReturn), {
          successMsg: "Dokument erfolgreich entfernt",
          errorMsg: "Löschen fehlgeschlagen"
        });
      } else {
        this.notificationService.notification.warn("Nur Dokument aus der laufenden Erklärung können entfernt werden.");
      }
    }
  }
}
