import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NotificationService } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { currentTaxReturn$$, currentTaxYear$ } from "src/app/app.state";
import { ChooseFileDialogComponent } from "src/app/components/choose-file-dialog/choose-file-dialog.component";
import { subjectAreaChildren, subjectAreaInvestor, subjectAreaPensioner, subjectAreaProperties, SubjectAreas, subjectAreaSpecialCosts, subjectAreaWorker, TaxReturn } from "src/app/models/tax-return.entity";
import { TaxReturnService } from "src/app/services/tax-return.service";

@Component({
  templateUrl: "./scan.component.html",
  styleUrls: ["./scan.component.scss"]
})
export class ScanComponent implements OnInit, OnDestroy {
  public uploadedFile: File;
  public year: number;
  public subjectArea: string;
  public option: string;

  public subjects: { [key: string]: string };
  public options: { [key: string]: string };

  public currentTaxYear: number;
  public currentTaxReturn: TaxReturn;

  private sub: Subscription = new Subscription();

  constructor(private taxReturnService: TaxReturnService, private notificationService: NotificationService, private dialog: MatDialog, private router: Router) { }

  public ngOnInit(): void {
    this.sub.add(currentTaxYear$.subscribe((cty) => this.currentTaxYear = cty));
    this.sub.add(currentTaxReturn$$.value$.subscribe((taxReturn) => this.currentTaxReturn = taxReturn));
    this.subjects = SubjectAreas;
  }

  public ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

  public changeSubjectArea() {
    switch (this.subjectArea) {
      case "children":
        this.options = subjectAreaChildren;
        break;
      case "specialCosts":
        this.options = subjectAreaSpecialCosts;
        break;
      case "worker":
        this.options = subjectAreaWorker;
        break;
      case "properties":
        this.options = subjectAreaProperties;
        break;
      case "investor":
        this.options = subjectAreaInvestor;
        break;
      case "pensioner":
        this.options = subjectAreaPensioner;
        break;
      default:
        break;
    }
  }

  public uploadFile() {
    this.dialog.open(ChooseFileDialogComponent, { data: { path: "general.filing", scan: true } }).afterClosed().subscribe((result) => {
      if (result) {
        this.uploadedFile = result.target.files[0];
      }
    });
  }

  public moveFileToCategory() {
    if (this.year !== this.currentTaxYear)
      this.currentTaxReturn.year = this.year;
    // TODO: move file from general.filing to selected category
    this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(this.currentTaxReturn), {
      successMsg: "Die Datei wurde in der passenden Kategorie deiner laufenden Steuererklärung hinzugefügt.",
      errorMsg: "Speichern fehlgeschlagen"
    });
  }

  public saveFileAtGeneralFiling() {
    this.notificationService.handlePromise(this.taxReturnService.updateTaxReturn(this.currentTaxReturn), {
      successMsg: "Die Datei wurde in der Ablage deiner laufenden Steuererklärung hinzugefügt",
      errorMsg: "Ablegen fehlgeschlagen"
    }).then(() => {
      this.router.navigateByUrl("/ablage");
    });
  }
}
