import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { TokenService } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { currentUser$$ } from "src/app/app.state";

@Component({
  templateUrl: "./register.component.html"
})
export class RegisterComponent {

  public formGroup: FormGroup;
  public hidePassword = true;

  constructor(formBuilder: FormBuilder, private axiosApiClient: AxiosApiClient, private tokenService: TokenService, private router: Router, private notificationService: NotificationService) {
    this.formGroup = formBuilder.group({
      email: new FormControl("", { updateOn: "blur", validators: [Validators.email, Validators.required] }),
      password: new FormControl("", { updateOn: "change", validators: [Validators.required, Validators.minLength(8)] })
    });
  }

  public showPasswordRequirements() {
    this.notificationService.popup.warn("Passwort Anforderungen", "Das Passwort muss aus min. 8 Zeichen bestehen, min. einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.");
  }

  public async register() {
    this.notificationService.handlePromise(this.axiosApiClient.get(UsersApi).registerUser({
      registerUserDto: {
        email: this.formGroup.get("email").value,
        password: this.formGroup.get("password").value
      }
    })).then(async (response) => {
      this.tokenService.setLoginToken(response.data.token);
      currentUser$$.setLoading().setValue((await this.axiosApiClient.get(UsersApi).getMyUser()).data);
      this.router.navigate(["/home"]);
    });
  }
}
