<div *ngIf="!showFiling" fxLayout="column" fxLayoutGap="40px" style="position: relative;">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon>cloud_upload</mat-icon>
            <h3 class="mat-title">Wähle eine Methode</h3>
        </div>
        <mat-icon (click)="close()" style="cursor:pointer;font-size:24px;width:24px;height:24px;">close</mat-icon>
    </div>
    <div class="content card-boxes" fxLayout="row" fxLayoutAlign="space-around center">
        <input #fileInput type="file" (change)="change($event)" style="display: none;">
        <div class="card-box centericon clickable padding" fxLayout="column" fxLayoutAlign="center center"
            fxLayoutGap="10px" fxFlex="48" (click)="fileInput.click()">
            <mat-icon color="primary">add_photo_alternate</mat-icon>
            <span class="mat-caption">
                Aus Galerie/Foto
            </span>
        </div>
        <div *ngIf="showFilingButton" class="card-box centericon clickable padding" fxLayout="column"
            fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="48" (click)="takeFromFiling()">
            <img class="dialog-icon" src="assets/icons/ablage_icon.svg" />
            <span class="mat-caption">
                Aus Ablage
            </span>
        </div>
    </div>
    <div class="uploading-overlay" fxLayout="column" fxLayoutAlign="center center" *ngIf="uploadProgress">
        <div>Datei wird hochgeladen</div>
        <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
        <div>{{uploadProgress}}%</div>
    </div>
</div>

<div *ngIf="showFiling" fxLayout="column" fxLayoutGap="40px" style="position: relative;">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img class="dialog-title-icon" src="assets/icons/ablage_icon.svg" />
            <h3 class="mat-title">Ablage {{currentTaxReturn.year}}</h3>
        </div>
        <mat-icon (click)="close()" style="cursor:pointer;font-size:24px;width:24px;height:24px;">close</mat-icon>
    </div>
    <div fxLayout="column">
        <mat-list>
            <mat-list-item *ngFor="let doc of currentTaxReturn.data.general.filing" (click)="moveFileFromFilingToSubjectAreaOption(doc)">
                <mat-icon mat-list-icon>
                    <mat-icon *ngIf="doc.type === 'application/pdf'">picture_as_pdf</mat-icon>
                    <img *ngIf="doc.type === 'image/jpeg' || doc.type === 'image/png'" [src]="doc.url" class="thumbnail" />
                </mat-icon>
                <div mat-line class="filename">{{doc.name}}</div>
                <div mat-line class="mat-caption">{{doc.type | filetype}} / {{doc.fileSize | filesize}}</div>
                <mat-icon>chevron_right</mat-icon>
            </mat-list-item>
        </mat-list>
    </div>
</div>